import user from './User';
import { getSocketInstance, closeSocketInstance, addSocketListener, removeSocketListener } from './webSocketService';
import { Modal, message } from 'antd';
const { confirm } = Modal;


export const handleBulkFolderChange = async (folderId, selectedRows, setFolderModalOpen, message, setLoading, setSelectedRows) => {
  setFolderModalOpen(false);
  setLoading(true);
  if (!folderId) return;
  
  for (const accountId of selectedRows) {
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/update`, {
        method: 'post',
        data: { id: accountId, folder: folderId }
      });
      if (!response.data.result) {
        message.error(`Failed to update folder for account ID ${accountId}`);
      }
    } catch (error) {
      message.error(`Server error when updating folder for account ID ${accountId}`);
    } finally{
    }
  }
  setLoading(false);
  setSelectedRows([]);
};


export const copyToClipboard = async (text, message) => {
  try {
    await navigator.clipboard.writeText(text);
    message.success('Copied to clipboard');
  } catch {
    message.error('Failed to copy');
  }
};

export const fetchFolders = async (setFolders, message) => {
  try {
    const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/getFolders`, { method: 'get', data: {} });
    if (response.data.result) {
      setFolders(response.data.data);
    } else {
      message.error(response.data.message);
    }
  } catch (error) {
    message.error('Failed to fetch folders');
  }
};



export const fetchLogs = async () => {
  try {
    const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/getLogs`, { method: 'get', data: {} });
    if (response.data.result) {
      return response.data.data
    } else {
      message.error(response.data.message);
    }
  } catch (error) {
    message.error('Failed to fetch logs');
  }
};



export const fetchAccounts = async (setLoading, fetchTasks, setAccounts, fetchFolders, message, setTasksNames) => {
  setLoading(true);
  await fetchFolders();
  try {
    const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/get`, { method: 'post', data: {} });
    if (response.data.result) {
      setAccounts(response.data.data);
      fetchTasks().then(tasks => {
        // let tasksCache = tasks;
        // let tmptasksData = {};
        // for(let key in tasksCache){
        //   let taskFunc = tasksCache[key].value
        //   tmptasksData[taskFunc] = tasksCache[key];
        // }
        setTasksNames(tasks)
      });
    } else {
      message.error(response.data.message);
    }
  } catch (error) {
    message.error('Failed to fetch accounts');
  } finally {
    setLoading(false);
  }
};

export const fetchTasks = async (message) => {
  try {
    const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/get`, { method: 'get' });
    return response.data.data;
  } catch (error) {
    message.error('Failed to fetch tasks');
    return [];
  }
};

export const handleRegister = async (bulk, setLoading, message, fetchAccounts) => {
  if (!bulk) setLoading(true);
  try {
    const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/add`, { method: 'post', data: {} });
    if (response.data.result) {
      try {
        let r = await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/create`, { 
          method: 'post', 
          data: { 
            type: 1, 
            func: 'register', 
            accId: '', 
            basId: response.data.data.id 
          } 
        });
        if (r.data.result) {
          if (!bulk) message.success('Task created successfully');
        } else {
          message.error(r.data.message);
        }
      } catch (error) {
        message.error(error);
      }
      if (!bulk) await fetchAccounts();
    } else {
      message.error(response.data.message);
    }
  } catch (err) {
    message.error('Failed to create account: ' + err);
  } finally {
    if (!bulk) setLoading(false);
  }
};

export const handleBulkRegister = async (setBulkModalOpen, setLoading, bulkCount, message, handleRegister, fetchAccounts) => {
  setBulkModalOpen(false);
  setLoading(true);
  try {
    for (let i = 0; i < bulkCount; i++) {
      await handleRegister(true);
    }
    message.success('Done');
    await fetchAccounts();
  } catch (err) {
    message.error('Failed to create accounts: ' + err);
  } finally {
    setLoading(false);
  }
};


export const handleMenuClick = async (key, accounts, selectedRows, setSelectedRows, tasksListsRefs, tasksNames, apiRowRefs, antyRefs, message, setLoading) => {
  if (key === 'change_folder')return;
  if(setLoading)setLoading(true);
  if (key.includes('task_')) {
    const selectedTask = key.split('task_')[1];
    for (const accountId of selectedRows) {
      const taskListRef = tasksListsRefs.current[accountId];
      if (taskListRef) {
        await taskListRef.handleTaskClick(tasksNames.find(task => task.value === selectedTask), true);
      }
    }
    if(setLoading)setLoading(false);
    setSelectedRows([]);
  } else if (key === 'Delete') {
    confirm({
      title: 'Are you sure you want to delete these accounts?',
      content: 'Deleting these accounts cannot be undone.',
      onOk: async () => {
        for (const crrkey in selectedRows) {
          let record = accounts.find(akk => akk.id == selectedRows[crrkey]);
          if (record) {
            await handleDropdownClick(key, record, false, false, message, true);
          }
        }
        message.success('Selected accounts have been deleted.');
        if(setLoading)setLoading(false);
        setSelectedRows([]);
      },
      onCancel() {
        message.info('Bulk delete action canceled');
        if(setLoading)setLoading(false);
      },
    });
  } 
  else if (key === 'Export') {
    confirm({
      title: 'Are you sure you want to Export these accounts?',
      content: 'Export & Deleting these accounts cannot be undone.',
      onOk: async () => {
        for (const crrkey in selectedRows) {
          let record = accounts.find(akk => akk.id == selectedRows[crrkey]);
          if (record) {
            await handleDropdownClick(key, record, false, false, message, true);
          }
        }
        message.success('Selected accounts have been exported.');
        if(setLoading)setLoading(false);
        setSelectedRows([]);
      },
      onCancel() {
        message.info('Bulk export action canceled');
        if(setLoading)setLoading(false);
      },
    });
  } 
  else if (key === 'Get') {
    for (const crrkey in selectedRows) {
        
    }
    if(setLoading)setLoading(false);
    setSelectedRows([]);
  }  else if (key.includes('api_')) {
    const apiAction = key.split('api_')[1];
    for (const accountId of selectedRows) {
      const apiRowRef = apiRowRefs.current[accountId];
      if (apiRowRef) {
        await apiRowRef.handleClick(apiAction, true);
      }
    }
    if(setLoading)setLoading(false);
    setSelectedRows([]);
  } else if (key === 'start_mobile') {
    for (const accountId of selectedRows) {
      const antyRef = antyRefs.current[accountId];
      if (antyRef) {
        await antyRef.handleStartMobile(true);
      }
    }
    if(setLoading)setLoading(false);
    setSelectedRows([]);
  } else if (key === 'start_desktop') {
    for (const accountId of selectedRows) {
      const antyRef = antyRefs.current[accountId];
      if (antyRef) {
        await antyRef.handleStartDesktop(true);
      }
    }
    if(setLoading)setLoading(false);
    setSelectedRows([]);
  }
};

export const updateData = (data, setAccounts) => {
  setAccounts(prevAccounts =>
    prevAccounts.map(account => (account.id === data.id ? { ...account, ...data } : account))
  );
};

export const handleDropdownClick = async (action, record, setSelectedAccount, setModalOpen, message, bulk = false) => {
  if (action === 'Data') {
    setSelectedAccount(record);
    setModalOpen(true);
  } else if (action == 'Delete') {
    if (!bulk) {
      confirm({
        title: 'Are you sure you want to delete this account?',
        content: `Deleting ${record.fName} cannot be undone.`,
        onOk: async () => {
          let r = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/delete`, { method: 'post', data: { id: record.id } });
          let executed = r?.data?.result ?? false;
          if (executed) {
            message.success(record.fName + ` Deleted!`);
            return true;
          } else if (r?.data?.message) {
            message.error(`Deleting ` + record.fName + `: ${r.data.message}`);
          } else {
            message.error(`Deleting ` + record.fName + `: Server returned unknown error`);
          }
          if (executed) return true;
          else return false;
        },
        onCancel() {
          message.info('Delete action canceled');
        },
      });
    } else {
      let r = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/delete`, { method: 'post', data: { id: record.id } });
      let executed = r?.data?.result ?? false;
      if (executed) return true;
      else return false;
    }
  } else if (action == 'Export') {
    if (!bulk) {
      confirm({
        title: 'Are you sure you want to export this account?',
        content: `Export & Delete ${record.fName} cannot be undone.`,
        onOk: async () => {
          let r = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/export`, { method: 'post', data: { id: record.id } });
          let executed = r?.data?.result ?? false;
          if (executed) {
            message.success(record.fName + ` Exported!`);
            return true;
          } else if (r?.data?.message) {
            message.error(`Exporting ` + record.fName + `: ${r.data.message}`);
          } else {
            message.error(`Exporting ` + record.fName + `: Server returned unknown error`);
          }
          if (executed) return true;
          else return false;
        },
        onCancel() {
          message.info('Exporting action canceled');
        },
      });
    } else {
      let r = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/export`, { method: 'post', data: { id: record.id } });
      let executed = r?.data?.result ?? false;
      if (executed) return true;
      else return false;
    }
  } else if (action == 'Get') {
    message.success('GET '+record.fName);
  }
};




export const useAntySocket = (setData, useEffect) => {
  useEffect(() => {
    let cleanup;
    
    const runAntySocket = async () => {
      const socketUrlBrowser = `${process.env.REACT_APP_WSS_URL}/anty/listen`;
      getSocketInstance(socketUrlBrowser);
      const handleMessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.result && data.data) {
            setData(data.data);
          } else {
            console.error(`Error: ${data.message}`);
          }
        } catch (err) {
          console.error('Error parsing WebSocket message:', err);
        }
      };
      addSocketListener(socketUrlBrowser, handleMessage);

      cleanup = () => {
        removeSocketListener(socketUrlBrowser, handleMessage);
        closeSocketInstance(socketUrlBrowser);
      };
    };

    runAntySocket();

    return () => {
      if (typeof cleanup === 'function') {
        cleanup();
      }
    };
  }, [setData]);
};




export const useTasksSocket = (setData, useEffect) => {
  useEffect(() => {
    let cleanup;
    
    const runAntySocket = async () => {
      const socketUrlBrowser = `${process.env.REACT_APP_WSS_URL}/tasks/listen`;
      getSocketInstance(socketUrlBrowser);
      const handleMessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.result && data.data) {
            setData(data.data);
          } else {
            console.error(`Error: ${data.message}`);
          }
        } catch (err) {
          console.error('Error parsing WebSocket message:', err);
        }
      };
      addSocketListener(socketUrlBrowser, handleMessage);

      cleanup = () => {
        removeSocketListener(socketUrlBrowser, handleMessage);
        closeSocketInstance(socketUrlBrowser);
      };
    };

    runAntySocket();

    return () => {
      if (typeof cleanup === 'function') {
        cleanup();
      }
    };
  }, [setData]);
};




export const useAccountsSocket = (setData, useEffect, setLoading, setTasksNames, setFolders, message) => {
  useEffect(() => {
    if(setLoading)setLoading(true)
    let cleanup;
    
    const runAntySocket = async () => {
      const socketUrlBrowser = `${process.env.REACT_APP_WSS_URL}/accounts/listen`;
      getSocketInstance(socketUrlBrowser);
      const handleMessage = async (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.result && data.data) {
            setData(data.data);
            fetchTasks().then(tasks => {
              setTasksNames(tasks)
            });
            await fetchFolders(setFolders, message);

            if(setLoading)setLoading(false)
          } else {
            console.error(`Error: ${data.message}`);
          }
        } catch (err) {
          console.error('Error parsing WebSocket message:', err);
        }
      };
      addSocketListener(socketUrlBrowser, handleMessage);
      cleanup = () => {
        removeSocketListener(socketUrlBrowser, handleMessage);
        closeSocketInstance(socketUrlBrowser);
      };
    };

    runAntySocket();

    return () => {
      if (typeof cleanup === 'function') {
        cleanup();
      }
    };
  }, [setData, setLoading]);
};