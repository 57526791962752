import React, { useState, useEffect } from 'react';
import { Tabs, Card, Button, Row, Col } from 'antd';
import PieChart from './PieChart';
import LineChart from './LineChart';
import { fetchLogs } from '../../../helpers/Accounts';
import RadarChart from './RadarChart'; // Ensure this is correctly placed at the top of your file

const Dashboard = () => {
  const [logs, setLogs] = useState([]);
  const [timeframe, setTimeframe] = useState('all'); // Manage timeframe globally

  useEffect(() => {
    async function loadLogs() {
      const fetchedLogs = await fetchLogs();
      setLogs(fetchedLogs);
    }
    loadLogs();
  }, []);

  const accountLogs = logs.filter(log => log.action.includes('Register')).reverse();
  const photoLogs = logs.filter(log => log.action.includes('Photo not passed') || log.action.includes('Photo passed')).reverse();
  const photoLogsLine = logs.filter(log => log.action.includes('Photo')).reverse();
  const accountLogsLine = logs.filter(log => log.action.includes('Register') || log.action.includes('exported')).reverse();


  // Timeframe buttons setup
  const TimeframeButtons = () => (
    <Button.Group style={{ marginBottom: 16 }}>
      <Button type={timeframe === '1' ? 'primary' : 'default'} onClick={() => setTimeframe('1')}>1 Day</Button>
      <Button type={timeframe === '7' ? 'primary' : 'default'} onClick={() => setTimeframe('7')}>7 Days</Button>
      <Button type={timeframe === '30' ? 'primary' : 'default'} onClick={() => setTimeframe('30')}>30 Days</Button>
      <Button type={timeframe === 'all' ? 'primary' : 'default'} onClick={() => setTimeframe('all')}>All Time</Button>
    </Button.Group>
  );

  const tabItems = [
    {
      label: "Accounts",
      key: "1",
      children: (
        <>
          <TimeframeButtons />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="Accounts Overview" style={{ padding: 15 }}>
                <LineChart data={accountLogsLine} timeframe={timeframe} />
              </Card>
            </Col>
            <Col xs={36} md={36}>
              <Card title="Account Pie">
                <PieChart data={accountLogs} timeframe={timeframe} />
              </Card>
            </Col>
            <Col xs={36} md={36}>
              <Card title="Accounts Hourly Rates">
                <RadarChart data={accountLogs} timeframe={timeframe}/>
              </Card>
            </Col>
          </Row>
        </>
      )
    },
    {
      label: "Photos",
      key: "2",
      children: (
        <>
          <TimeframeButtons />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title="Photo Verification Overview" style={{ padding: 15 }}>
                <LineChart data={photoLogsLine} timeframe={timeframe} />
              </Card>
            </Col>
            <Col xs={36} md={36}>
              <Card title="Photo Pie">
                <PieChart data={photoLogs} timeframe={timeframe} />
              </Card>
            </Col>
            <Col xs={36} md={36}>
              <Card title="Photo Hourly Rates">
                <RadarChart data={photoLogs} timeframe={timeframe}/>
              </Card>
            </Col>

          </Row>
        </>
      )
    }
  ];

  return <Tabs items={tabItems} defaultActiveKey="1" style={{ width: '100%' }} />;
};

export default Dashboard;
