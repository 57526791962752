import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Table, Button, Spin, Modal, Input, InputNumber, App, Select, Tabs, Layout, Dropdown } from 'antd';
import { DownOutlined, CopyOutlined, SearchOutlined, FilterOutlined, PlusOutlined, DeleteOutlined,UserAddOutlined, UsergroupAddOutlined, DesktopOutlined, MobileOutlined, ReloadOutlined, InfoCircleOutlined, PlayCircleOutlined, FolderOpenOutlined, ExportOutlined } from '@ant-design/icons';
import { copyToClipboard, fetchFolders, fetchAccounts, fetchTasks, updateData, handleDropdownClick, useAntySocket, useTasksSocket, handleRegister, handleMenuClick, handleBulkRegister, useAccountsSocket, handleBulkFolderChange } from '../../../helpers/Accounts';
import Tags from '../../globals/tags';
import AntyComponent from '../../globals/anty';
import TasksLists from '../../globals/tasks';
import InfoModal from '../../globals/accData';
import ApiRow from '../../globals/apiRow';
import user from '../../../helpers/User';

const { Content } = Layout;
const { Option } = Select;

const Ferma = () => {
  const { message } = App.useApp();
  const [accounts, setAccounts] = useState([]);
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [folderModalOpen, setFolderModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [bulkCount, setBulkCount] = useState(1);
  const [searchFilters, setSearchFilters] = useState({});
  const [activeTab, setActiveTab] = useState("All");
  const [selectedRows, setSelectedRows] = useState([]);
  const [tasksNames, setTasksNames] = useState([]);
  const [tasks, setTasks] = useState([]);
  const tasksListsRefs = useRef({});
  const apiRowRefs = useRef({});
  const antyRefs = useRef({});
  const [activeBrowsers, setActiveBrowsers] = useState([]);

  useAntySocket(setActiveBrowsers, useEffect)
  useTasksSocket(setTasks, useEffect);
  useAccountsSocket(setAccounts, useEffect, setLoading, setTasksNames, setFolders, message);



  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchFilters(prev => ({ ...prev, [selectedKeys.column]: selectedKeys.value }));
  };

  const resetFilter = (dataIndex, confirm) => {
    setSearchFilters(prev => {
      const newFilters = { ...prev };
      delete newFilters[dataIndex];
      return newFilters;
    });
    confirm();
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8, position: 'relative' }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch({ column: dataIndex, value: selectedKeys[0] }, confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch({ column: dataIndex, value: selectedKeys[0] }, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          className='resetFilterClassname'
          onClick={() => { setSelectedKeys([]); resetFilter(dataIndex, confirm); }}
          size="small"
          style={{ width: 90, marginLeft: 5 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: () => {
      const isFiltered = Boolean(searchFilters[dataIndex]);
      return (
        <div style={{ position: 'relative' }}>
          <FilterOutlined style={{ color: isFiltered ? '#1890ff' : undefined }} />
        </div>
      );
    },
    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a[dataIndex] < b[dataIndex] ? -1 : 1
  });

  const columns = useMemo(() => [
    {
      title: 'Browser',
      dataIndex: 'Browser',
      key: 'id',
      render: (text, record) => <AntyComponent ref={el => (antyRefs.current[record.id] = el)} account={record} activeBrowsers={activeBrowsers} />,
    },
    {
      title: 'Account',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text, message)}>
          {text}
        </Button>
      ),
      ...getColumnSearchProps('id')
    },
    {
      title: 'Name',
      dataIndex: 'name',
      className: "akkNameRow",
      key: 'name',
      ...getColumnSearchProps('name'),
      render: text => (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text, message)}>
          {text}
        </Button>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Folder',
      dataIndex: 'Folder',
      key: 'folder',
      ...getColumnSearchProps('folder'),
      render: (text, record) => (
        <Select
          style={{ width: 167 }}
          value={record.folder}
          onChange={async (key, value) => {
            try {
              const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/update`, { method: 'post', data: { folder: key, id: record.id } });
              if (response.data.result) {
                setAccounts(prevAccounts =>
                  prevAccounts.map(account =>
                    account.id === record.id ? { ...account, folder: value } : account
                  )
                );
                let leftFolders = accounts.filter(account => account.folder === record.folder).length - 1;
                if (leftFolders < 1) setActiveTab(value.children);
              } else {
                message.error(response.data.message);
              }
            } catch (error) {
              message.error('Failed to update folder!');
            }
          }}
        >
          {folders.map(folder => (
            <Option key={folder.id} value={folder.id}>{folder.name}</Option>
          ))}
        </Select>
      ),
      sorter: (a, b) => a.folder.localeCompare(b.folder),
    },
    {
      title: 'API',
      dataIndex: 'api',
      className: "apiRow",
      key: 'api',
      render: (_, record) => (
        <ApiRow ref={el => (apiRowRefs.current[record.id] = el)} record={record} buttons={{ today: true, yesterday: false }} />
      ),
    },
    {
      title: 'RK',
      dataIndex: 'rk',
      className: "rkRow",
      key: 'rk',
      ...getColumnSearchProps('rk'),
      render: text => text ? (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text, message)}>
          {text}
        </Button>
      ) : (
        ''
      ),
      sorter: (a, b) => a.rk ? a.rk.localeCompare(b.rk) : 1,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      className: "tagsRow",
      key: 'tags',
      ...getColumnSearchProps('tags'),
      render: (_, record) => (
        <Tags record={record} records={accounts} setAccounts={setAccounts} table="accounts" />
      ),
      sorter: (a, b) => (a.tags ? a.tags.length : 0) - (b.tags ? b.tags.length : 0),
    },
    {
      title: 'Actions',
      key: 'actions',
      className: "actionsRow",
      render: (_, record) => (
        <Dropdown
          menu={{ 
            items: [
              { key: 'Data', label: (<><PlusOutlined /> Data</>) },
              { key: 'Get', label: (<><DeleteOutlined /> Get</>) },
              { key: 'Export', label: (<span><ExportOutlined /> Export</span>) },
              { key: 'Delete', label: (<span style={{color: "red"}}><DeleteOutlined /> Delete</span>) },
            ], 
            onClick: ({ key }) => handleDropdownClick(key, record, setSelectedAccount, setModalOpen, message) 
          }}
        >
          <Button>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
    {
      title: 'Tasks',
      className: "tasksRow",
      dataIndex: 'Tasks',
      key: 'id',
      render: (text, record) => (
        <TasksLists ref={el => (tasksListsRefs.current[record.id] = el)} account={record} taskStates={tasks.filter(task => task.basId == record.id)} tasksNames={tasksNames}/>
      ),
    },
  ], [accounts, folders, getColumnSearchProps, message]);

  const handleTabChange = key => {
    setActiveTab(key);
  };

  const filteredAccounts = useMemo(() => {
    if (activeTab === 'All') {
      return accounts;
    } else {
      return accounts.filter(account => account.folder === activeTab);
    }
  }, [accounts, activeTab]);

  const tabItems = useMemo(() => {
    const folderItems = folders.map(folder => {
      const filteredFolderAccounts = accounts.filter(account => account.folder_id === folder.id);
      return filteredFolderAccounts.length > 0 ? {label: `${folder.name} (${filteredFolderAccounts.length})`, key: folder.name} : null;
    }).filter(Boolean);

    return [{ label: `All (${accounts.length})`, key: 'All' }, ...folderItems];
  }, [accounts, folders]);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys),
  };

  return (
    <div style={{ width: '100%' }}>
          <div className="topButtons">
        <Button icon={<UserAddOutlined />} onClick={() => handleRegister(false, setLoading, message, async () => {
          await fetchAccounts(setLoading, async () => {
            const tasks = await fetchTasks(message);
            return tasks;
          }, setAccounts, async () => {
            await fetchFolders(setFolders, message);
          }, message, setTasksNames);
        })}>Register Account</Button>
        <Button icon={<UsergroupAddOutlined />} onClick={() => setBulkModalOpen(true)}>Bulk Register</Button>
        <Button icon={<ReloadOutlined />} onClick={() => fetchAccounts(setLoading, async () => {
          const tasks = await fetchTasks(message);
          return tasks;
        }, setAccounts, async () => {
          await fetchFolders(setFolders, message);
        }, message, setTasksNames)}>Refresh Table</Button>
        {selectedRows.length > 0 && (
          <Dropdown menu={{
            items: [
              {
                type: 'group',
                label: 'Folder',
                children: [
                  { 
                    key: 'change_folder',
                    label: 'Change Folder',
                    icon: <FolderOpenOutlined />,  // Make sure to import FolderOpenOutlined from ant-design icons
                    onClick: () => setFolderModalOpen(true)  
                  },
                ]
              },
              {
                type: 'group',
                label: 'Anty Actions',
                children: [
                  { key: 'start_desktop', label: 'Start Desktop', icon: <DesktopOutlined /> },
                  { key: 'start_mobile', label: 'Start Mobile', icon: <MobileOutlined /> }
                ]
              },
              {
                type: 'group',
                label: 'API',
                children: [
                  { key: 'api_today', label: 'Today', icon: <ReloadOutlined /> },
                ].filter(Boolean),
              },
              {
                type: 'group',
                label: 'Actions',
                children: ['Delete', 'Get', 'Export'].map(action => ({
                  key: action,
                  label: `${action} Selected`,
                  icon: action == 'Delete' ? <DeleteOutlined /> : action == 'Export' ? <ExportOutlined /> : <InfoCircleOutlined />,
                }))
              },
              {
                type: 'group',
                label: 'Tasks',
                children: tasksNames.map(task => ({
                  label: task.name,
                  key: `task_${task.value}`,
                  icon: <PlayCircleOutlined />,
                })),
              }
            ],
            onClick: ({ key }) => handleMenuClick(key, accounts, selectedRows, setSelectedRows, tasksListsRefs, tasksNames, apiRowRefs, antyRefs, message, setLoading)
          }}>
            <Button icon={<DownOutlined />}>Bulk Actions</Button>
          </Dropdown>
        )}
      </div>
      <Tabs activeKey={activeTab} onChange={handleTabChange} items={tabItems} />
      <Spin spinning={loading}>
        <Table
          dataSource={filteredAccounts}
          columns={columns}
          rowKey="id"
          rowSelection={rowSelection}
        />
      </Spin>
      <InfoModal
        visible={modalOpen}
        onClose={() => setModalOpen(false)}
        data={selectedAccount || {}}
        allData={accounts}
        updateData={(data) => updateData(data, setAccounts)}
      />
      <Modal
        title="Bulk Register Accounts"
        open={bulkModalOpen}
        onOk={() => handleBulkRegister(setBulkModalOpen, setLoading, bulkCount, message, handleRegister, async () => {
          await fetchAccounts(setLoading, async () => {
            const tasks = await fetchTasks(message);
            return tasks;
          }, setAccounts, async () => {
            await fetchFolders(setFolders, message);
          }, message, setTasksNames);
        })}
        onCancel={() => setBulkModalOpen(false)}
      >
        <InputNumber
          min={1}
          value={bulkCount}
          onChange={setBulkCount}
          style={{ width: '100%' }}
        />
      </Modal>
      <Modal
        title="Change Folder for Selected Accounts"
        open={folderModalOpen}
        onOk={() => handleBulkFolderChange(selectedFolder, selectedRows, setFolderModalOpen, message, setLoading, setSelectedRows)}
        onCancel={() => setFolderModalOpen(false)}
      >
        <Select
          style={{ width: '100%' }}
          placeholder="Select a folder"
          value={selectedFolder}
          onChange={value => setSelectedFolder(value)}
        >
          {folders.map(folder => (
            <Option key={folder.id} value={folder.id}>{folder.name}</Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default Ferma;
