import React from 'react';
import { Table, Dropdown, Button, Space, Tag, Image, Menu, Modal, Tooltip } from 'antd';
import { EditOutlined, StopOutlined, DeleteOutlined, DownOutlined, CheckOutlined } from '@ant-design/icons';
import Flag from 'react-world-flags';
import countriesData from '../../globals/countries.json';

const { confirm } = Modal;

const OfferTable = ({ data, loading, onEdit, onDelete, onDisable, onEnable, rowSelection }) => {
  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure you want to delete this offer?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDelete(record);
      },
    });
  };

  const menuItems = (record) => [
    {
      key: 'edit',
      label: 'Edit',
      icon: <EditOutlined />,
      onClick: () => onEdit(record),
    },
    {
      key: 'disable',
      label: record.status === 'active' ? 'Disable' : 'Enable',
      icon: record.status === 'active' ? <StopOutlined /> : <CheckOutlined />,
      onClick: () => (record.status === 'active' ? onDisable(record) : onEnable(record)),
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: <DeleteOutlined style={{ color: 'red' }} />,
      onClick: () => showDeleteConfirm(record),
    },
  ];

  const renderExpandableContent = (text) => {
    if (!text || text.length <= 100) {
      return <div dangerouslySetInnerHTML={{ __html: text }} />;
    }

    return (
      <Tooltip title={<div dangerouslySetInnerHTML={{ __html: text }} />} placement="topLeft">
        <div>
          <div dangerouslySetInnerHTML={{ __html: `${text.substring(0, 100)}...` }} />
        </div>
      </Tooltip>
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => image ? (
        <Image
          src={`${process.env.REACT_APP_API_URL}/${image}`}
          alt="Offer"
          width={100}
          style={{ cursor: 'pointer' }}
          preview={{ src: `${process.env.REACT_APP_API_URL}/${image}`, width: 'auto', height: 'auto' }}
        />
      ) : 'No Image',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filters: [...new Set(data.map(item => item.name))].map(name => ({ text: name, value: name })),
      onFilter: (value, record) => record.name.includes(value),
    },
    {
      title: 'Offer',
      dataIndex: 'offer',
      key: 'offer',
      sorter: (a, b) => a.offer.localeCompare(b.offer),
      filters: [...new Set(data.map(item => item.offer))].map(offer => ({ text: offer, value: offer })),
      onFilter: (value, record) => record.offer.includes(value),
    },
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
      sorter: (a, b) => a.partner.localeCompare(b.partner),
      filters: [...new Set(data.map(item => item.partner))].map(partner => ({ text: partner, value: partner })),
      onFilter: (value, record) => record.partner.includes(value),
    },
    {
      title: 'Payout',
      dataIndex: 'payout',
      key: 'payout',
      sorter: (a, b) => a.payout - b.payout,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => renderExpandableContent(text),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      render: (text) => renderExpandableContent(text),
    },
    {
      title: 'Call Center Details',
      dataIndex: 'callcenter_details',
      key: 'callcenter_details',
      render: (text) => renderExpandableContent(text),
    },
    {
      title: 'Countries',
      dataIndex: 'countries',
      key: 'countries',
      render: (countries) => (
        <div style={{ display: 'flex', gap: '4px' }}>
          {countries.map((country) => (
            <Tag key={country}>
              <Flag code={country} height="10" width="24" />
            </Tag>
          ))}
        </div>
      ),
      filters: [...new Set(data.flatMap(item => item.countries))].map(country => ({
        text: countriesData.countries[country]?.en || country,
        value: country,
      })),
      onFilter: (value, record) => record.countries.includes(value),
    },
    {
      title: 'Languages',
      dataIndex: 'languages',
      key: 'languages',
      render: (languages, record) => {
        let crrLangs = new Set();
        Object.keys(countriesData.countries).forEach((country) => {
          if (record.countries.includes(country)) {
            Object.entries(countriesData.countries[country].languages).forEach(([langKey, langObj]) => {
              if (languages.includes(langKey)) {
                crrLangs.add(langObj.en);
              }
            });
          }
        });
        const selectedLanguagesArray = Array.from(crrLangs);
        return (
          <Space>
            {selectedLanguagesArray.map((lang, index) => (
              <Tag key={index}>{lang}</Tag>
            ))}
          </Space>
        );
      },
      filters: [...new Set(data.flatMap(item => item.languages))].map(language => ({
        text: language,
        value: language,
      })),
      onFilter: (value, record) => record.languages.includes(value),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown menu={{ items: menuItems(record) }}>
          <Button icon={<DownOutlined />}>Actions</Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      loading={loading}
      rowSelection={rowSelection}
    />
  );
};

export default OfferTable;
