import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  UserAddOutlined, AreaChartOutlined, FundOutlined, ClusterOutlined,
  MailOutlined, CloudOutlined, PictureOutlined, BuildOutlined, FileTextOutlined,
  CreditCardOutlined, StopOutlined, UnorderedListOutlined, PlayCircleOutlined,
  PauseCircleOutlined, LinkOutlined, SettingOutlined, LogoutOutlined, OpenAIOutlined,
  UserSwitchOutlined, ProductOutlined, UsergroupAddOutlined
} from '@ant-design/icons';
import user from '../helpers/User';
import '../Header.css'; // Custom CSS for the scrollbar
import SettingsComponent from './routing/user/settings'; // Adjust the import path accordingly

const { Sider } = Layout;
const userName = user?.login ?? 'Profile';
const menuItems = [
  { key: '/', label: 'Overview', path: '/', icon: <AreaChartOutlined /> },
  { key: '/stats', label: 'Stats', path: '/stats', icon: <FundOutlined /> },
  { key: '/ferma', label: 'Farm', path: '/ferma', icon: <UsergroupAddOutlined /> },
  { key: '/offers', label: 'Offers', path: '/offers', icon: <ProductOutlined /> },
  { key: '/tasks', label: 'Tasks', path: '/tasks', icon: <OpenAIOutlined /> },
  {
    key: '/akks', label: 'Accounts', path: '/akks', icon: <UserAddOutlined />,
    children: [
      { key: '/akks/active', label: 'Active', path: '/akks/active', icon: <PlayCircleOutlined /> },
      { key: '/akks/waiting', label: 'Waiting', path: '/akks/waiting', icon: <PauseCircleOutlined /> },
      { key: '/akks/banned', label: 'Banned', path: '/akks/banned', icon: <StopOutlined /> },
      { key: '/akks/all', label: 'All', path: '/akks/all', icon: <UnorderedListOutlined /> }
    ]
  },
  { key: '/domains', label: 'Domains', path: '/domains', icon: <ClusterOutlined /> },
  { key: '/emails', label: 'Emails', path: '/emails', icon: <MailOutlined /> },
  { key: '/cloaking', label: 'Cloaking', path: '/cloaking', icon: <CloudOutlined /> },
  { key: '/creatives', label: 'Creatives', path: '/creatives', icon: <PictureOutlined /> },
  { key: '/texts', label: 'Texts', path: '/texts', icon: <FileTextOutlined /> },
  { key: '/cards', label: 'Cards', path: '/cards', icon: <CreditCardOutlined /> },
  {
    key: '/profile', label: userName, path: '/profile', icon: <UserSwitchOutlined />,
    children: [
      { key: 'user/settings', label: 'Settings', path: '/user/settings', icon: <SettingOutlined /> },
      { key: '/user/logout', label: 'Logout', path: '/user/logout', icon: <LogoutOutlined /> }
    ]
  }
];

// Define the setIsModalVisible function
const Header = ({ user }) => {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem('siderCollapsed');
    return savedState ? JSON.parse(savedState) : false;
  });

  const [openKeys, setOpenKeys] = useState(() => {
    const savedKeys = localStorage.getItem('openKeys');
    return savedKeys ? JSON.parse(savedKeys) : menuItems.map(item => item.key);
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('siderCollapsed', JSON.stringify(collapsed));
  }, [collapsed]);

  useEffect(() => {
    localStorage.setItem('openKeys', JSON.stringify(openKeys));
  }, [openKeys]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const transformMenuItems = (menuItems) => {
    return menuItems.map((item) => {
      if (item.children) {
        return {
          key: item.key,
          icon: item.icon,
          label: item.label,
          children: transformMenuItems(item.children)
        };
      }

      let href = item.key == 'user/settings' ? '' : item.path;
      return {
        key: item.key,
        icon: item.icon,
        onClick: (item) => {
          if (item.key == 'user/settings') {
            setIsModalVisible(true);
            return false;
          }
        },
        label: item.key == 'user/settings' ? item.label : <Link to={href}>{item.label}</Link>
      }

    });
  };

  return (
    <>
      <Sider
        theme="dark"
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        style={{ maxHeight: '100vh', minHeight: '100vh', overflowY: 'auto', position: "fixed", zIndex: 2 }} // Make Sider scrollable and set max height to screen height
        className="custom-scrollbar" // Apply custom scrollbar
      >
        <Menu
          theme="dark"
          selectedKeys={[location.pathname]}
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          items={transformMenuItems(menuItems)}
        />
      </Sider>
      <SettingsComponent
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
    </>
  );
}

export default Header;
