import React from 'react';
import { Table, Dropdown, Button, Tag, Menu, Modal, Tooltip, Popover, Image, Space } from 'antd';
import { EditOutlined, StopOutlined, DeleteOutlined, DownOutlined, CheckOutlined } from '@ant-design/icons';
import Flag from 'react-world-flags';

const { confirm } = Modal;

const CloakingTable = ({ data, loading, onEdit, onDelete, onDisable, onEnable, rowSelection }) => {
  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure you want to delete this cloaking setting?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDelete(record);
      },
    });
  };

  const menuItems = (record) => [
    {
      key: 'edit',
      label: 'Edit',
      icon: <EditOutlined />,
      onClick: () => onEdit(record),
    },
    {
      key: 'disable',
      label: record.status === 'active' ? 'Disable' : 'Enable',
      icon: record.status === 'active' ? <StopOutlined /> : <CheckOutlined />,
      onClick: () => (record.status === 'active' ? onDisable(record) : onEnable(record)),
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: <DeleteOutlined style={{ color: 'red' }} />,
      onClick: () => showDeleteConfirm(record),
    },
  ];

  const renderCountry = (countryStr) => {
    const country = typeof countryStr === 'string' ? JSON.parse(countryStr) : countryStr;
    return (
      <Tag>
        <Flag code={country.code} height="10" /> {country.en}
      </Tag>
    );
  };

  const renderLanguage = (countryStr) => {
    const country = typeof countryStr === 'string' ? JSON.parse(countryStr) : countryStr;
    let language = 'N/A';
    if (typeof country.language === 'string') {
      country.language = JSON.parse(country.language);
    }
    language = country?.language?.en ?? 'N/A';
    return (
      <Tag>{language}</Tag>
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Image',
      dataIndex: ['offer', 'image'],
      key: 'image',
      render: (image) => image ? (
        <Image
          src={`${process.env.REACT_APP_API_URL}/${image}`}
          alt="Offer"
          width={100}
          style={{ cursor: 'pointer' }}
          preview={{ src: `${process.env.REACT_APP_API_URL}/${image}`, width: 'auto', height: 'auto' }}
        />
      ) : 'No Image',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filters: [...new Set(data.map(item => item.name))].map(name => ({ text: name, value: name })),
      onFilter: (value, record) => record.name.includes(value),
    },
    {
      title: 'Offer Name',
      dataIndex: ['offer', 'name'],
      key: 'offer_name',
      render: (offerName) => offerName,
    },
    {
      title: 'Partner',
      dataIndex: ['offer', 'partner'],
      key: 'partner',
      render: (partner) => partner,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (country) => (
        <Space>
          {renderCountry(country)}
          {renderLanguage(country)}
        </Space>
      ),
      filters: [...new Set(data.map(item => {
        const country = typeof item.country === 'string' ? JSON.parse(item.country) : item.country;
        return country.en;
      }))].map(country => ({
        text: country,
        value: country,
      })),
      onFilter: (value, record) => {
        const country = typeof record.country === 'string' ? JSON.parse(record.country) : record.country;
        return country.en.includes(value);
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown menu={{ items: menuItems(record) }}>
          <Button icon={<DownOutlined />}>Actions</Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      loading={loading}
      rowSelection={rowSelection}
    />
  );
};

export default CloakingTable;
