import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';
import { Button, Dropdown, Popover, Spin, Badge, App } from 'antd';
import { DownOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import user from '../../helpers/User';


const TasksLists = forwardRef(({ account, taskStates, tasksNames }, ref) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const { message } = App.useApp();



  const handleTaskAction = async (task, isDelete = false, bulk = false) => {
    try {
      await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/update`, {
        method: 'POST',
        data: {
          task_id: task.id,
          force: 1,
          status: 'New',
          del: isDelete ? true : false,
        },
      });

      if (isDelete) {
        message.success('Task deleted');
      } else {
        message.success('Task updated');
      }
    } catch (error) {
      message.error('Failed to update task');
    }
  };

  const handleTaskClick = async (task, bulk = false) => {
    try {
      let r = await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/create`, { 
        method: 'post', 
        data: { 
          type: task.is_desktop, 
          func: task.value, 
          accId: account.rk, 
          priority: task.priority,
          basId: account.id
        } 
      });
      if(!bulk){
        if(r.data.result)message.success('Task created successfully');
        else message.error(r.data.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  // Count active and error tasks based on status
  const activeTaskCount = Object.values(taskStates).filter(task => task.status === 'New' && account.id == task.basId).length;
  const errorTaskCount = Object.values(taskStates).filter(task => task.status !== 'New' && account.id == task.basId).length;
  let errorOffset = -3;
  if(activeTaskCount > 0 && errorTaskCount > 0) errorOffset = 12;
  
  const items = useMemo(() => tasksNames.map(task => {
    const taskState = taskStates.filter(crr => crr.func == task.value && crr.basId == account.id)?.[0]??false;
    const disabled = taskState??false;
    const danger = taskState && taskState.status != 'New'?true:false;
    const content = taskState && (
      <div>
        <b>Status</b>: {taskState.status.replace('<BR>', ', ')} <DeleteOutlined style={{color: "red"}} onClick={() => handleTaskAction(taskState, true)}/>  <ReloadOutlined onClick={() => handleTaskAction(taskState)} />
      </div>
    );

    return {
      key: task.id,
      disabled: disabled,
      danger: danger,
      label: content ? (
        <Popover content={content}>
          <span style={{ color: danger ? 'red' : 'inherit' }}>{task.name}</span>
        </Popover>
      ) : (
        task.name
      ),
      onClick: () => handleTaskClick(task) // Add onClick event
    };
  }), [tasksNames, taskStates]);

  // Expose a method to parent component to get non-disabled tasks
  useImperativeHandle(ref, () => ({
    getNonDisabledTasks: () => tasksNames.filter(task => !taskStates[task.value]),
    handleTaskClick: (task, bulk) => handleTaskClick(task, bulk)
  }));

  return (
    <Spin spinning={loading}>
      <Dropdown menu={{ items }}>
        <Badge color={"blue"} count={activeTaskCount} offset={[-3, 0]} size="small">
          <Badge count={errorTaskCount} offset={[errorOffset, 0]} size="small">
            <Button>
              Tasks <DownOutlined />
            </Button>
          </Badge>
        </Badge>
      </Dropdown>
    </Spin>
  );
});

export default TasksLists;
