import React, { useState, useEffect } from 'react';
import { Tabs, Button, Spin, App, Modal } from 'antd';
import { PlusOutlined, ReloadOutlined, StopOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import user from '../../../helpers/User';
import OfferForm from './OfferForm';
import OfferTable from './OfferTable';

const { confirm } = Modal;

const Offers = () => {
  const { message } = App.useApp();
  const [activeData, setActiveData] = useState([]);
  const [disabledData, setDisabledData] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingBundle, setEditingBundle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [activeTab, setActiveTab] = useState('1'); // Track the active tab

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/offers/get`, { method: 'GET' });
      const data = response.data?.data ?? false;
      if (!data) {
        message.error(response?.data.message ?? 'Unknown error while fetching offers');
        return false;
      }
      const parsedData = data.map(item => ({
        ...item,
        countries: JSON.parse(item.countries).split(','),
        languages: JSON.parse(item.languages).split(','),
      }));
      setActiveData(parsedData.filter(item => item.status === 'active'));
      setDisabledData(parsedData.filter(item => item.status === 'disabled'));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const openEditDrawer = (record) => {
    setEditingBundle(record);
    setDrawerVisible(true);
  };

  const handleAddNew = () => {
    setEditingBundle(null);
    setDrawerVisible(true);
  };

  const handleAddOrUpdate = async (formData) => {
    try {
      const apiEndpoint = editingBundle ? `${process.env.REACT_APP_API_URL}/offers/update` : `${process.env.REACT_APP_API_URL}/offers/add`;
      const response = await user.makeRequest(apiEndpoint, { method: 'POST', data: formData });
      const result = response.data;
      if (result?.result) {
        message.success("Done.");
      } else {
        message.error(result?.message ?? 'Unknown Error');
      }
      setDrawerVisible(false);
      setEditingBundle(null);
      fetchData();
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while saving data.');
    }
  };

  const handleDelete = async (record) => {
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/offers/delete`, { method: 'DELETE', data: { id: record.id } });
      const result = response.data;
      if (result?.result) {
        message.success("Offer deleted successfully.");
        fetchData();
      } else {
        message.error(result?.message ?? 'Unknown Error');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while deleting the offer.');
    }
  };

  const handleDisable = async (record) => {
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/offers/disable`, { method: 'POST', data: { id: record.id } });
      const result = response.data;
      if (result?.result) {
        message.success("Offer disabled successfully.");
        fetchData();
      } else {
        message.error(result?.message ?? 'Unknown Error');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while disabling the offer.');
    }
  };

  const handleEnable = async (record) => {
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/offers/enable`, { method: 'POST', data: { id: record.id } });
      const result = response.data;
      if (result?.result) {
        message.success("Offer enabled successfully.");
        fetchData();
      } else {
        message.error(result?.message ?? 'Unknown Error');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while enabling the offer.');
    }
  };

  const handleBulkDelete = async () => {
    confirm({
      title: 'Are you sure you want to delete selected offers?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await Promise.all(selectedRowKeys.map(id => user.makeRequest(`${process.env.REACT_APP_API_URL}/offers/delete`, { method: 'DELETE', data: { id } })));
          message.success("Selected offers deleted successfully.");
          setSelectedRowKeys([]);
          fetchData();
        } catch (error) {
          console.error('Error:', error);
          message.error('An error occurred while deleting selected offers.');
        }
      },
    });
  };

  const handleBulkDisable = async () => {
    try {
      await Promise.all(selectedRowKeys.map(id => user.makeRequest(`${process.env.REACT_APP_API_URL}/offers/disable`, { method: 'POST', data: { id } })));
      message.success("Selected offers disabled successfully.");
      setSelectedRowKeys([]);
      fetchData();
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while disabling selected offers.');
    }
  };

  const handleBulkEnable = async () => {
    try {
      await Promise.all(selectedRowKeys.map(id => user.makeRequest(`${process.env.REACT_APP_API_URL}/offers/enable`, { method: 'POST', data: { id } })));
      message.success("Selected offers enabled successfully.");
      setSelectedRowKeys([]);
      fetchData();
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while enabling selected offers.');
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const tabItems = [
    {
      key: '1',
      label: 'Active',
      children: (
        <>
          <Spin spinning={loading}>
            <OfferTable data={activeData} loading={loading} onEdit={openEditDrawer} onDelete={handleDelete} onDisable={handleDisable} onEnable={handleEnable} rowSelection={rowSelection} />
          </Spin>
        </>
      ),
    },
    {
      key: '2',
      label: 'Disabled',
      children: (
        <>
          <Spin spinning={loading}>
            <OfferTable data={disabledData} loading={loading} onEdit={openEditDrawer} onDelete={handleDelete} onDisable={handleDisable} onEnable={handleEnable} rowSelection={rowSelection} />
          </Spin>
        </>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <div className="topButtons">
        <Button icon={<PlusOutlined />} onClick={handleAddNew}>Add New Offer</Button>
        <Button icon={<ReloadOutlined />} onClick={fetchData}>Refresh</Button>
        {selectedRowKeys.length > 0 && (
          <>
            {activeTab === '1' ? (
              <Button icon={<StopOutlined />} onClick={handleBulkDisable}>Disable Selected</Button>
            ) : (
              <Button icon={<CheckOutlined />} onClick={handleBulkEnable}>Enable Selected</Button>
            )}
            <Button icon={<DeleteOutlined />} onClick={handleBulkDelete}>Delete Selected</Button>
          </>
        )}
      </div>
      <Tabs
        defaultActiveKey="1"
        items={tabItems}
        onChange={(key) => {
          setActiveTab(key);
          setSelectedRowKeys([]); // Clear selection when tab changes
        }}
      />
      <OfferForm
        open={drawerVisible}
        onCancel={() => setDrawerVisible(false)}
        onSubmit={handleAddOrUpdate}
        editingBundle={editingBundle}
      />
    </div>
  );
};

export default Offers;
