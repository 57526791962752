import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, App, Space } from 'antd';
import { MobileOutlined, DesktopOutlined, LoadingOutlined } from '@ant-design/icons';
import user from '../../helpers/User';
import axios from 'axios';

const AntyComponent = forwardRef(({ activeBrowsers, account }, ref) => {
  const { message } = App.useApp();
  const [isMobileActive, setIsMobileActive] = useState(false);
  const [isDesktopActive, setIsDesktopActive] = useState(false);
  const [loadingPC, setLoadingPC] = useState(false);
  const [loadingMOB, setLoadingMOB] = useState(false);
  const [pcProxyReady, setpcProxyReady] = useState(false);
  const [mobProxyReady, setMobProxyReady] = useState(false);


  
  useEffect(() => {
      let keyName = account.basId ? 'basId' : 'id';
      const accountExists = activeBrowsers.some(item => item.account === account[keyName]);
      const desktopEntry = activeBrowsers.find(item => item.account === account[keyName] && item.is_desktop);
      const mobileEntry = activeBrowsers.find(item => item.account === account[keyName] && !item.is_desktop);

      const pcWithProxy = activeBrowsers.find(item => item.account === account[keyName] && item.proxy_id && item.is_desktop);
      const mobWithProxy = activeBrowsers.find(item => item.account === account[keyName] && item.proxy_id && !item.is_desktop);
      
      setIsMobileActive(false);
      setIsDesktopActive(false);
      setpcProxyReady(false);
      setMobProxyReady(false);

      if (mobileEntry) setIsMobileActive(accountExists);
      if (desktopEntry) setIsDesktopActive(accountExists);

      if (pcWithProxy) setpcProxyReady(accountExists);
      if (mobWithProxy) setMobProxyReady(accountExists);

  }, [activeBrowsers, account]);


  const handleStopMobile = async () => {
    setLoadingMOB(true);
    try {
      let keyName = account.basId ? 'basId' : 'id';
      let antyRecord = activeBrowsers.find(item => item.account === account[keyName] && item.is_desktop == false);

      let r = await axios.get(`http://localhost:${antyRecord.port}/stop`, {
        params: {
          accId: account.rk,
          is_desktop: 0      
        },
        timeout: 5000
      });
      if (r) {
        message.success('Stop MOB account '+account[keyName]);
        setMobProxyReady(false);
      } else {
        message.error(`Stop MOB request failed for account: `+account[keyName]);
      }
      setLoadingMOB(false);
    } catch(err) {
      message.error(err.message);
      setLoadingMOB(false);
    }
  };

  const handleStopDesktop = async () => {
    setLoadingPC(true);
    try {
      let keyName = account.basId ? 'basId' : 'id';
      
      let antyRecord = activeBrowsers.find(item => item.account === account[keyName] && item.is_desktop == true);

      let r = await axios.get(`http://localhost:${antyRecord.port}/stop`, {
        params: {
          accId: account.rk,
          is_desktop: 1
        },
        timeout: 5000
      });
      if (r) {
        setpcProxyReady(false);
        message.success('Stop account '+account[keyName]);
      } else {
        message.error(`Stop request failed for account: `+account[keyName]);
      }
      setLoadingPC(false);
    } catch(err) {
      message.error(err.message);
      setLoadingPC(false);
    }
  };

  const handleStartMobile = async (bulk = false) => {
    setLoadingMOB(true);
    try {
      let keyName = account.basId ? 'basId' : 'id';
      let r = await user.makeRequest(`${process.env.REACT_APP_ANTY_URL}/start`, {
        method: "GET",
        params: {
          basId: account[keyName],
          accId: account.rk
        }
      });
      if(bulk){
        if (r.data) {
          message.success(<>
            Start {account.fName} {account.lName} <MobileOutlined /> 
          </>);
        } else {
          message.error(<>
            Start {account.fName} {account.lName} Failed! <MobileOutlined /> 
          </>);
        }
      }
      setLoadingMOB(false);
    } catch(err) {
      message.error(err.message);
      setLoadingMOB(false);
    }
  };

  const handleStartDesktop = async (bulk = false) => {
    setLoadingPC(true);
    try {
      let keyName = account.basId ? 'basId' : 'id';
      let r = await user.makeRequest(`${process.env.REACT_APP_ANTY_URL}/start`, {
        method: "GET",
        params: {
          basId: account[keyName],
          accId: account.rk,
          type: 1
        }
      });
      if(bulk){
        if (r.data) {
          message.success(<>
            Start {account.fName} {account.lName} <DesktopOutlined />
          </>);
        } else {
          message.error(<>
            Start {account.fName} {account.lName} Failed! <DesktopOutlined />  
          </>);
        }
      }

      setLoadingPC(false);
    } catch(err) {
      message.error(err.message);
      setLoadingPC(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleStartMobile,
    handleStartDesktop,
    handleStopMobile,
    handleStopDesktop
  }));

  return (
    <Space direction='vertical'>
      <Button onClick={isDesktopActive ? handleStopDesktop : handleStartDesktop} style={{ padding: '6px' }} 
              type={isDesktopActive ? 'primary' : 'default'}>
        {loadingPC || (isDesktopActive && !pcProxyReady) ? <LoadingOutlined /> : <DesktopOutlined />}
      </Button>
      <Button onClick={isMobileActive ? handleStopMobile : handleStartMobile} style={{ padding: '6px' }} 
              type={isMobileActive ? 'primary' : 'default'}>
        {loadingMOB || (isMobileActive && !mobProxyReady) ? <LoadingOutlined /> : <MobileOutlined />}
      </Button>
    </Space>
  );
});

export default AntyComponent;

