import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadarController,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';

// Make sure to import and register RadarController and RadialLinearScale for Radar charts
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadarController, // This registers the radar type
  RadialLinearScale, // This is required for the radial axes
  ArcElement,
  Tooltip,
  Legend
);

export default ChartJS;
