import React from 'react';
import { Form, Radio, InputNumber, Button, Switch, Input, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const { Option } = Select;

const RegisterSettings = () => {
  return (
    <>
        <Form.Item
        label="Регистрация аккаунтов"
        name="antyRegister"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
        >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
        </Form.Item>


        <Form.Item
        label="Экспорт аккаунтов"
        name="antyRegisterExport"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
        >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
        </Form.Item>


        <Form.Item label="Тип регистрации" name="registerPlatforms">
          <Select>
            <Option value="all">Mob+PC</Option>
            <Option value="mob">Mob</Option>
            <Option value="pc">PC</Option>
          </Select>
        </Form.Item>


        <Form.Item label="Пауза между регами (сек.)" name="registrationDelay">
        <Input type="number" min="1" max="999999999" placeholder="600" />
        </Form.Item>

        <Form.Item label="Макс потоков регистрации" name="registrationMaxCount">
        <Input type="number" min="1" max="100" placeholder="1" />
        </Form.Item>






        <Form.Item
        label="Генерация фото миджорней"
        name="midjourneyPhotos"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
        >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
        </Form.Item>

        <Form.Item
        label="Генерация фото Leonardo"
        name="useLeonardo"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
        >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
        </Form.Item>



        <Form.Item
        label="Использовать лоакальные фото"
        name="localsPhotos"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
        >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
        </Form.Item>


    </>
  );
};
export default RegisterSettings;
