import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Drawer, message, Form, Spin, Button, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { SaveOutlined, SettingOutlined } from '@ant-design/icons';
import AutomaticsSettings from './AutomaticsSettings';
import TextSettings from './TextSettings';
import RegisterSettings from './registerSettings';
import ZalivkiModeSettings from './ZalivkiModeSettings';
import ZalivkiModeAutoSettings from './ZalivkiModeAutoSettings';
import ArtAntySettings from './ArtAntySettings';
import user from '../../../../helpers/User';

const SettingsComponent = ({ visible, onClose }) => {
  const [form] = useForm();
  const [activeTab, setActiveTab] = useState('phpAutomatics');
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(false);
  const settingsFetched = useRef(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (visible && !settingsFetched.current) {
        setLoading(true);
        settingsFetched.current = true;
        try {
          let settings = await user.getSettings();
          if (Object.keys(settings).length > 0) {
            setSettings(settings);
            form.setFieldsValue(settings);
          }
        } catch (error) {
          message.error('Failed to load settings');
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [visible, form]);

  const handleFinish = async (values) => {
    setSaving(true);
    const filteredValues = Object.keys(values).reduce((acc, key) => {
      if (values[key] !== undefined && !key.includes('targetSettings')) {
        acc[key] = values[key];
      }
      return acc;
    }, {});
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/anty/updateSettings`, { method: 'post', data: { settings: filteredValues } });
      if (response.data.result) {
        message.success('Settings updated');
      } else {
        message.error(response?.data?.message ?? 'Unknown error while updating settings');
      }
    } catch (error) {
      message.error('Failed to update settings');
    } finally {
      setSaving(false);
    }
  };

  

  const tabItems = [
    { label: 'Автоматика', key: 'phpAutomatics', children: <AutomaticsSettings /> },
    { label: 'Текста', key: 'textSettings', children: <TextSettings /> },
    { label: 'Заливки', key: 'zalivkiMode', children: <ZalivkiModeSettings /> },
    { label: 'Ферма', key: 'RegisterSettings', children: <RegisterSettings /> },
    { label: 'Авто - Заливки', key: 'zalivkiModeAuto', children: <ZalivkiModeAutoSettings /> },
    { label: 'ArtAnty', key: 'zalivkiArtAnty', children: <ArtAntySettings /> }
  ];

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      title={<div ><SettingOutlined /> {user.login}</div>}
      width="650px"
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button icon={<SaveOutlined />} type="primary" htmlType="submit" form="settingsForm" loading={saving}>
            Save
          </Button>
        </Space>
      }
    >
      {loading ? <Spin style={{ width: '100%', textAlign: 'center', marginTop: '20px' }} /> : (
        <Form id="settingsForm" form={form} onFinish={handleFinish} layout="vertical" style={{ paddingBottom: '60px' }}>
          <Tabs activeKey={activeTab} onChange={setActiveTab} style={{ marginTop: '-24px' }} items={tabItems} />
        </Form>
      )}
    </Drawer>
  );
};

export default SettingsComponent;
