import React from 'react';
import { Form, Radio, Button, Space, Input, Select, Switch } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const { Option } = Select;

const AutomaticsSettings = () => (
  <>


    <Form.Item label="Генерация вайтов" name="phpAutomaticsWhite">
      <Select>
        <Option value="always">Перегенерить всегда при создание заливок</Option>
        <Option value="enabled">При создание заливок и проверки акков</Option>
        <Option value="onlyZalivki">Только при создание заливок</Option>
        <Option value="onlyAkkCheck">Только при проверки акков</Option>
        <Option value="off">Выкл</Option>
      </Select>
    </Form.Item>


    
    <Form.Item label="Режим заливки" name="zalivkiUseMidjourney">
      <Select>
        <Option value="off">Креосы</Option>
        <Option value="enabled" style={{ display: 'none' }}>Midjourney</Option>
      </Select>
    </Form.Item>
    <Form.Item
      label="Автовключение LIK & FC"
      name="phpAutomaticsFC"
      valuePropName="checked"
      getValueProps={(value) => ({ checked: value === 'enabled' })}
      getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
    >
      <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
    </Form.Item>
    <Form.Item
      label="Стопы без трафа"
      name="phpAutomaticsNoTraf"
      valuePropName="checked"
      getValueProps={(value) => ({ checked: value === 'enabled' })}
      getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
    >
      <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
    </Form.Item>
    <Form.Item
      label="Стопы плохих компаний"
      name="phpAutomaticsBadStats"
      valuePropName="checked"
      getValueProps={(value) => ({ checked: value === 'enabled' })}
      getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
    >
      <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
    </Form.Item>
    <Form.Item
      label="Стопы не найденных крео + архив"
      name="phpAutomaticsArchiveCreo"
      valuePropName="checked"
      getValueProps={(value) => ({ checked: value === 'enabled' })}
      getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
    >
      <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
    </Form.Item>
    <Form.Item
      label="Бусты Разгона"
      name="phpAutomaticsBustTest"
      valuePropName="checked"
      getValueProps={(value) => ({ checked: value === 'enabled' })}
      getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
    >
      <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
    </Form.Item>
    <Form.Item label="Час Буста" name="busttingHour">
      <Input type="number" min="0" max="23" placeholder="4" />
    </Form.Item>
    <Form.Item
      label="Бусты с 1$"
      name="phpAutomaticsBustStart"
      valuePropName="checked"
      getValueProps={(value) => ({ checked: value === 'enabled' })}
      getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
    >
      <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
    </Form.Item>
    <Form.Item label="Максимальный бюджет для больших ГЕО" name="maximumBudget">
      <Input type="number" min="7" max="999" placeholder="50" />
    </Form.Item>
    <Form.Item label="Максимальный бюджет для маленьких ГЕО" name="maximumBudgetSmallGeo">
      <Input type="number" min="7" max="999" placeholder="25" />
    </Form.Item>
    <Form.Item label="Шаг буста" name="budgetBoostStep">
      <Input type="number" min="1" max="999" />
    </Form.Item>
    <Form.Item
      label="Повышать бюджет для хороших часов"
      name="phpAutomaticsBustSchedule"
      valuePropName="checked"
      getValueProps={(value) => ({ checked: value === 'enabled' })}
      getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
    >
      <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
    </Form.Item>
    <Form.Item label="Тратить в эти часы больше на" name="phpAutomaticsBustScheduleValue">
      <Input type="number" min="1" max="999" placeholder="5" />
    </Form.Item>
    </>
);

export default AutomaticsSettings;
