import React, { useEffect, useState, useRef } from 'react';
import { App } from 'antd';
import { useLocation } from 'react-router-dom';
import { fetchTasks, useAntySocket, useTasksSocket } from '../../../helpers/Accounts';
import TaskTabs from './TaskTabs';
import TopButtons from './TopButtons';
import user from '../../../helpers/User';

const TasksPage = () => {
  const { message } = App.useApp();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [tasksData, setTasksData] = useState([]);
  const [activeBrowsers, setActiveBrowsers] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentTab, setCurrentTab] = useState('all');
  const hasFetchedTasksRef = useRef(false); // Reference to track the first render

  const fetchAndSetTasks = () => {
    setLoading(true);
    fetchTasks(message)
      .then(tasks => {
        let tasksCache = tasks;
        let tmptasksData = {};
        for (let key in tasksCache) {
          let taskFunc = tasksCache[key].value;
          tmptasksData[taskFunc] = tasksCache[key];
        }
        setTasksData(tmptasksData);
      })
      .catch(() => { }) // Error is already handled in fetchTasks
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (hasFetchedTasksRef.current) {
      fetchAndSetTasks();
    } else {
      hasFetchedTasksRef.current = true; // Set to true after the first execution
    }
  }, [location]);

  useAntySocket(setActiveBrowsers, useEffect);
  useTasksSocket(setTasks, useEffect);

  const handleTaskAction = async (task, isDelete = false, bulk = false) => {
    if (!bulk) setLoading(true);
    try {
      await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/update`, {
        method: 'POST',
        data: {
          updateArtTask: 1,
          task_id: task.id,
          force: 1,
          status: 'New',
          del: isDelete ? true : false,
        },
      });

      if (isDelete) {
        if (!bulk) message.success('Task deleted');
      } else {
        if (!bulk) message.success('Task updated');
      }
    } catch (error) {
      message.error('Failed to update task');
    } finally {
      if (!bulk) setLoading(false);
    }
  };

  const handleTasksAction = async (action) => {
    try {
      setLoading(true);
      let filteredTasks;
      if (action === 'restartErrors') {
        filteredTasks = tasks.filter(task => task.status !== 'New');
      } else {
        const isDelete = action === 'deleteErrors';
        filteredTasks = tasks.filter(task => isDelete ? task.status !== 'New' : task.status === 'New');
      }

      for (const task of filteredTasks) {
        await handleTaskAction(task, action !== 'restartErrors', true);
      }

      message.success("Done.");
    } catch (error) {
      message.error('Failed to perform action on tasks');
    } finally {
      setLoading(false);
    }
  };

  const handleBulkAction = async (action) => {
    try {
      setLoading(true);
      for (const taskId of selectedRowKeys) {
        const task = tasks.find(task => task.id === taskId);
        if (task) {
          await handleTaskAction(task, action === 'delete', true);
        }
      }
      message.success("Bulk action completed.");
    } catch (error) {
      message.error('Failed to perform bulk action on tasks');
    } finally {
      setLoading(false);
      setSelectedRowKeys([]); // Clear selection after bulk action
    }
  };

  const categorizeTasks = (tasks) => {
    const categorizedTasks = { 'Other': [] };
    tasks.forEach((task) => {
      const { status } = task;
      if (!categorizedTasks[status]) {
        categorizedTasks[status] = [];
      }
      categorizedTasks[status].push(task);
    });

    const finalCategorizedTasks = { 'Other': [] };
    Object.keys(categorizedTasks).forEach((status) => {
      if (categorizedTasks[status].length >= 6) {
        finalCategorizedTasks[status] = categorizedTasks[status];
      } else {
        finalCategorizedTasks['Other'] = [...finalCategorizedTasks['Other'], ...categorizedTasks[status]];
      }
    });

    return finalCategorizedTasks;
  };

  const categorizedTasks = categorizeTasks(tasks);
  const userLogin = user.login;

  const taskTabs = [
    {
      key: 'all',
      label: 'All Tasks',
      tasks: tasks,
      tasksData: tasksData,
      activeBrowsers: activeBrowsers,
      count: tasks.length,
      handleTaskAction: handleTaskAction
    },
    {
      key: 'my-tasks',
      label: 'My Tasks',
      tasks: tasks.filter(task => task.login === userLogin),
      tasksData: tasksData,
      activeBrowsers: activeBrowsers,
      count: tasks.filter(task => task.login === userLogin).length,
      handleTaskAction: handleTaskAction
    },
  ];

  Object.keys(categorizedTasks).forEach((status) => {
    taskTabs.push({
      key: status,
      label: status,
      tasks: categorizedTasks[status],
      tasksData: tasksData,
      activeBrowsers: activeBrowsers,
      count: categorizedTasks[status].length,
      handleTaskAction: handleTaskAction
    });
  });

  return (
    <div style={{ width: '100%' }}>
      <TopButtons
        handleTasksAction={handleTasksAction}
        selectedRowKeys={selectedRowKeys}
        handleBulkAction={handleBulkAction}
      />
      <div className="landsList">
        <TaskTabs
          taskTabs={taskTabs}
          setSelectedRowKeys={setSelectedRowKeys}
          setCurrentTab={setCurrentTab}
          selectedRowKeys={selectedRowKeys}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default TasksPage;
