import React, { useEffect, useState } from 'react';
import { Drawer, Form, Input, Select, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Flag from 'react-world-flags';
import countriesData from '../../globals/countries.json';

const { Option } = Select;
const { TextArea } = Input;

const OfferForm = ({ open, onCancel, onSubmit, editingBundle }) => {
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      if (editingBundle) {
        form.setFieldsValue(editingBundle);
        setSelectedCountries(editingBundle.countries);
        setSelectedLanguages(editingBundle.languages);
      } else {
        form.resetFields();
        setSelectedCountries([]);
        setSelectedLanguages([]);
        setFileList([]);
      }
    }
  }, [open, editingBundle, form]);

  useEffect(() => {
    if (selectedCountries.length > 0) {
      let languages = {};
      selectedCountries.forEach(country => {
        let countryLanguages = countriesData.countries[country]?.languages;
        if (countryLanguages) {
          languages = { ...languages, ...countryLanguages };
        }
      });
      setSelectedLanguages(selectedLanguages.filter(crrKey => Object.keys(languages).includes(crrKey)));
      setAvailableLanguages(languages);
    } else {
      setAvailableLanguages([]);
    }
  }, [selectedCountries]);

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        if (key !== 'image') {
          formData.append(key, values[key]);
        }
      });

      if (!editingBundle && fileList.length === 0) {
        message.error('Image is required for new offers.');
        return;
      }

      if (fileList.length > 0) {
        formData.append('image', fileList[0].originFileObj);
      }

      onSubmit(formData);
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while saving data.');
    }
  };

  return (
    <Drawer
      title={editingBundle ? "Edit Offer" : "Add New Offer"}
      open={open}
      onClose={() => {
        form.resetFields();
        onCancel();
      }}
      width={720}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={() => {
            form.resetFields();
            onCancel();
          }} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={handleOk} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical">
        {editingBundle && (
          <Form.Item name="id" label="ID" hidden>
            <Input type="hidden" />
          </Form.Item>
        )}
        <Form.Item name="name" label="Name" rules={[{ required: true }]}><Input /></Form.Item>
        <Form.Item name="offer" label="Offer" rules={[{ required: true }]}><Input /></Form.Item>
        <Form.Item name="partner" label="Partner" rules={[{ required: true }]}><Input /></Form.Item>
        <Form.Item name="countries" label="Countries" rules={[{ required: true }]} initialValue={editingBundle ? editingBundle.countries : []}>
          <Select mode="multiple" placeholder="Select countries" onChange={setSelectedCountries}>
            {countriesData.countries && Object.entries(countriesData.countries).map(([code, country]) => (
              <Option key={code} value={code}>
                <Flag code={code} height="12" /> {country.en}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="languages" label="Languages" rules={[{ required: true }]} initialValue={editingBundle ? editingBundle.languages : []}>
          <Select mode="multiple" placeholder="Select languages" onChange={setSelectedLanguages} value={selectedLanguages} showSearch>
            {availableLanguages && Object.entries(availableLanguages).map((crrLangData, key) => (
              <Option key={crrLangData[0]} value={crrLangData[0]}>
                {crrLangData[1].en} ({crrLangData[1].code})
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="payout" label="Payout"><Input type="number" /></Form.Item>
        <Form.Item name="description" label="Description"><TextArea rows={2} /></Form.Item>
        <Form.Item name="notes" label="Notes"><TextArea rows={2} /></Form.Item>
        <Form.Item name="callcenter_details" label="Call Center Details"><TextArea rows={2} /></Form.Item>
        <Form.Item name="image" label="Offer Image" rules={[{ required: !editingBundle }]}>
          <Upload beforeUpload={() => false} multiple={false} fileList={fileList} onChange={handleFileChange}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default OfferForm;
