import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from 'antd';
import Header from './header';
import NotFound from './routing/notFound';
import Akks from './routing/akks';
import Domains from './routing/domains';
import Ferma from './routing/ferma';
import Dashboard from './routing/main';
import Stats from './routing/stats';
import Logout from './routing/user/logout';
import Settings from './routing/user/settings';
import Emails from './routing/emails';
import Tasks from './routing/tasks';
import Creatives from './routing/creatives';
import Offers from './routing/offers';
import Cloaking from './routing/cloaking';

const { Content } = Layout;

function StartTracker({ user }) { // Destructure user from props
  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Header user={user} />
        <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/akks" element={<Akks />} />
            <Route path="/domains" element={<Domains />} />
            <Route path="/ferma" element={<Ferma />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/user/logout" element={<Logout />} />
            <Route path="/user/settings" element={<Settings />} />
            <Route path="/emails" element={<Emails />} />
            <Route path="/creatives" element={<Creatives />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/cloaking" element={<Cloaking />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
}

export default StartTracker;
