import React, { useEffect, useState } from 'react';
import { Drawer, Form, Input, Select, Button, message, Popover } from 'antd';
import Flag from 'react-world-flags';
import countriesData from '../../globals/countries.json';
import user from '../../../helpers/User';

const { Option } = Select;
const { TextArea } = Input;

const CloakingForm = ({ open, onCancel, onSubmit, editingBundle }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [availableOffers, setAvailableOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      if (editingBundle) {
        const countryData = typeof editingBundle.country === 'string' ? JSON.parse(editingBundle.country) : editingBundle.country;
        form.setFieldsValue({
          ...editingBundle,
          country: countryData.code,
          language: countryData.language.key,
          offer: editingBundle.offer.id
        });
        setSelectedCountry(countryData.code);
        setSelectedLanguage(countryData.language);
        setSelectedOffer(editingBundle.offer.id);
      } else {
        resetForm();
      }
    }
  }, [open, editingBundle, form]);

  useEffect(() => {
    if (selectedCountry) {
      user.makeRequest(`${process.env.REACT_APP_API_URL}/offers/get`, { method: 'GET' }).then((response) => {
        const offers = response.data.data.filter(offer => offer.countries.includes(selectedCountry));
        setAvailableOffers(offers);
      }).catch(error => {
        console.error('Error fetching offers:', error);
        message.error('An error occurred while fetching offers.');
      });
    } else {
      setAvailableOffers([]);
    }
  }, [selectedCountry]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const selectedLang = countriesData.countries[selectedCountry].languages[selectedLanguage];
      values.country = JSON.stringify({
        code: selectedCountry,
        ...countriesData.countries[selectedCountry],
        language: {
          code: selectedLanguage,
          ...selectedLang,
        },
      });
      onSubmit(values);
      resetForm();
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while saving data.');
    }
  };

  const resetForm = () => {
    form.resetFields();
    setSelectedCountry(null);
    setSelectedLanguage(null);
    setSelectedOffer(null);
  };

  const handleDrawerClose = () => {
    resetForm();
    onCancel();
  };

  return (
    <Drawer
      title={editingBundle ? "Edit Cloaking Setting" : "Add New Cloaking Setting"}
      open={open}
      onClose={handleDrawerClose}
      width={720}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={handleDrawerClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={handleOk} type="primary">
            Submit
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical">
        {editingBundle && (
          <Form.Item name="id" label="ID" hidden>
            <Input type="hidden" />
          </Form.Item>
        )}
        <Form.Item name="name" label="Name" rules={[{ required: true }]}><Input /></Form.Item>
        <Form.Item name="country" label="Country" rules={[{ required: true }]}>
          <Select
            showSearch
            placeholder="Select country"
            onChange={(value) => {
              setSelectedCountry(value);
              form.setFieldsValue({ language: undefined }); // Reset language when country changes
            }}
            value={selectedCountry}
          >
            {countriesData.countries && Object.entries(countriesData.countries).map(([code, country]) => (
              <Option key={code} value={code}>
                <Flag code={code} height="12" /> {country.en}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {selectedCountry && (
          <Form.Item name="language" label="Language" rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder="Select language"
              onChange={(value) => setSelectedLanguage(value)}
              value={selectedLanguage}
            >
              {countriesData.countries[selectedCountry]?.languages && Object.entries(countriesData.countries[selectedCountry].languages).map(([code, language]) => (
                <Option key={code} value={code}>
                  {language.en} ({language.code})
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {selectedCountry && selectedLanguage && (
          <Form.Item name="offer" label="Offer" rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder="Select offer"
              onChange={setSelectedOffer}
              value={selectedOffer}
              filterOption={(input, option) =>
                typeof option.children === 'string' && option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {availableOffers.map(offer => (
                <Option key={offer.id} value={offer.id}>
                  <Popover content={<div>{offer.description}</div>} title={offer.name}>
                    <img src={`${process.env.REACT_APP_API_URL}/${offer.image}`} alt={offer.name} style={{ width: '20px', marginRight: '8px' }} />
                    {offer.name}
                  </Popover>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Drawer>
  );
};

export default CloakingForm;
