import React, { useMemo } from 'react';
import { Radar } from 'react-chartjs-2';
import './chartConfig'; // Ensures all Chart.js components are registered correctly

const RadarChart = ({ data, timeframe }) => {
    const now = Date.now();

    // Define the timeframe in milliseconds based on the input
    const getTimeframeMilliseconds = () => {
        switch (timeframe) {
            case '1':
                return 24 * 60 * 60 * 1000; // Last 24 hours
            case '7':
                return 7 * 24 * 60 * 60 * 1000; // Last 7 days
            case '30':
                return 30 * 24 * 60 * 60 * 1000; // Last 30 days
            case 'all':
            default:
                return Number.MAX_SAFE_INTEGER; // Effectively all data
        }
    };

    const timeframeDuration = getTimeframeMilliseconds();

    // useMemo to calculate data based on timeframe for each action
    const chartData = useMemo(() => {
        const labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
        const actionData = {};

        data.forEach(item => {
            const itemTime = parseInt(item.date);
            const hoursAgo = Math.floor((now - itemTime) / (60 * 60 * 1000)) % 24;
            if (now - itemTime < timeframeDuration) {
                const action = item.action;
                if (!actionData[action]) {
                    actionData[action] = Array.from({ length: 24 }, () => 0);
                }
                actionData[action][hoursAgo]++;
            }
        });

        const datasets = Object.keys(actionData).map(action => ({
            label: action,
            data: actionData[action],
            backgroundColor: getColorForAction(action, 0.2),
            borderColor: getColorForAction(action, 1),
            borderWidth: 1,
            pointBackgroundColor: getColorForAction(action, 1)
        }));

        return {
            labels,
            datasets
        };
    }, [data, timeframeDuration, now]);

    return (
        <div style={{ height: '300px' }}>
            <Radar data={chartData} options={{ scales: { r: { beginAtZero: true } } }} />
        </div>
    );
};

// Function to assign colors based on action type
const getColorForAction = (action, opacity) => {
    const baseColors = {
        'failed': `rgba(255, 99, 132, ${opacity})`,
        'not': `rgba(255, 99, 132, ${opacity})`,
        'done': `rgba(54, 162, 235, ${opacity})`,
        'passed': `rgba(54, 162, 235, ${opacity})`,
        'exported': `rgba(75, 192, 192, ${opacity})`,
        'Photo': `rgba(255, 206, 86, ${opacity})`
    };

    for (const key of Object.keys(baseColors)) {
        if (action.includes(key)) {
            return baseColors[key];
        }
    }

    return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${opacity})`; // Random color for other actions
};

export default RadarChart;
