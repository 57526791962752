import React, { useRef, useState, useEffect } from 'react';
import { Button, Space, Tooltip, App } from 'antd';
import { ReloadOutlined, ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import user from '../../helpers/User';

const calculateTimeSinceUpdate = (date, now) => {
  if (!date) return '~';
  const updatedDate = new Date(date);
  const diffTime = Math.abs(now - updatedDate);
  const diffSeconds = Math.floor(diffTime / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  if (diffSeconds < 60) {
    return `${diffSeconds} s.`;
  } else if (diffMinutes < 60) {
    return `${diffMinutes} m.`;
  } else if (diffHours < 24) {
    return `${diffHours} h.`;
  } else if (diffDays < 30) {
    return `${diffDays} d.`;
  } else if (diffMonths < 12) {
    return `${diffMonths} M.`;
  } else {
    return `${diffYears} y.`;
  }
};

const ApiRow = React.forwardRef(({ record, buttons = { today: false, yesterday: false }}, ref) => {
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleClick = async(label, bulk = false) => {
    if(!bulk)setLoading(true)
    try {
      let r = await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/create`, { 
        method: 'post', 
        data: { 
          type: 1, 
          func: 'refhreshCookieAndToken', 
          accId: record.rk, 
          priority: 3,
          basId: record.id
        } 
      });
      if(!bulk){
        if(r.data.result)message.success('Task created successfully');
        else message.error(r.data.message);
      }
    } catch (error) {
      message.error(error);
    }finally{
      if(!bulk)setLoading(false)
    }
  };

  React.useImperativeHandle(ref, () => ({
    handleClick
  }));

  return (
        <Space  direction='vertical' style={{textAlign: "center"}}>
          {buttons.today && (
            <Tooltip title="Today">
              {!loading?
              <Button icon={<ReloadOutlined />} onClick={() => handleClick('today')} />:
              <Button disabled={true} icon={<LoadingOutlined />} />
              }
            </Tooltip>
          )}
          {calculateTimeSinceUpdate(record.updated, now)}
        </Space>
  );
});

export default ApiRow;
