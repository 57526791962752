import React, { useState } from 'react';
import { Tabs, Table, Button, Popover, Tag, Dropdown, Menu } from 'antd';

const { TabPane } = Tabs;

const creatives = [
  {
    id: 1,
    type: 'image',
    src: 'https://via.placeholder.com/150',
    status: 'active',
    language: 'en',
    stats: {
      CLOAK: 78, CLICK: 2689, PROFIT: 360, LEAD: 72, ROI: 174,
      CTR: 4.1, DAY: 13, APR: 44, CPC: 0.18, CPL: 6.8, LAND: 17,
      ZAL: 92, ZAL_OK: 76, START: 69
    },
    variations: {
      RO: { CLOAK: 25, CLICK: 800, PROFIT: 100, LEAD: 20 },
      ES: { CLOAK: 30, CLICK: 900, PROFIT: 150, LEAD: 25 },
      MX: { CLOAK: 23, CLICK: 989, PROFIT: 110, LEAD: 27 }
    }
  },
  {
    id: 2,
    type: 'video',
    src: 'https://www.w3schools.com/html/mov_bbb.mp4',
    status: 'disabled',
    language: 'es',
    stats: {
      CLOAK: 45, CLICK: 1024, PROFIT: 210, LEAD: 45, ROI: 67,
      CTR: 1.8, DAY: 7, APR: 28, CPC: 0.21, CPL: 4.7, LAND: 10,
      ZAL: 50, ZAL_OK: 40, START: 35
    },
    variations: {
      RO: { CLOAK: 10, CLICK: 300, PROFIT: 50, LEAD: 10 },
      ES: { CLOAK: 20, CLICK: 500, PROFIT: 80, LEAD: 15 },
      MX: { CLOAK: 15, CLICK: 224, PROFIT: 80, LEAD: 20 }
    }
  },
  // Add more creatives as needed
];

const renderStats = (stats) => (
  <div>
    {Object.entries(stats).map(([key, value]) => (
      <div key={key}>{`${key}: ${value}`}</div>
    ))}
  </div>
);

const Creatives = () => {
  const [activeKey, setActiveKey] = useState('1');

  const handleTabChange = key => {
    setActiveKey(key);
  };

  const columns = [
    {
      title: 'Preview',
      dataIndex: 'src',
      key: 'src',
      render: (text, record) =>
        record.type === 'image' ? (
          <img src={text} alt={`Creative ${record.id}`} style={{ width: '100px' }} />
        ) : (
          <video width="100px" controls>
            <source src={text} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ),
    },
    { title: 'Language', dataIndex: 'language', key: 'language' },
    {
      title: 'Statistics',
      key: 'stats',
      render: (text, record) => (
        <Popover content={renderStats(record.stats)} title="Statistics">
          <Tag>{`${record.language.toUpperCase()} Stats`}</Tag>
        </Popover>
      ),
    },
    {
      title: 'Variations',
      key: 'variations',
      render: (text, record) => (
        <>
          {Object.keys(record.variations).map(variation => (
            <Popover content={renderStats(record.variations[variation])} title={`${variation} Stats`} key={variation}>
              <Tag>{variation}</Tag>
            </Popover>
          ))}
        </>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Dropdown overlay={
          <Menu>
            <Menu.Item key="edit">Edit</Menu.Item>
            <Menu.Item key="disable">Disable</Menu.Item>
            <Menu.Item key="delete">Delete</Menu.Item>
          </Menu>
        }>
          <Button>Actions</Button>
        </Dropdown>
      ),
    },
  ];

  const renderTable = status => {
    const filteredData = creatives.filter(creative => creative.status === status);
    return <Table columns={columns} dataSource={filteredData} rowKey="id" />;
  };

  return (
    <div>
      <Tabs activeKey={activeKey} onChange={handleTabChange}>
        <TabPane tab="Active" key="1">
          {renderTable('active')}
        </TabPane>
        <TabPane tab="Disabled" key="2">
          {renderTable('disabled')}
        </TabPane>
        <TabPane tab="Add New" key="3">
          <Button type="primary">Add New Creative</Button>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Creatives;
