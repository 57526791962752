import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import './chartConfig'; // Ensure Chart.js components are registered

const LineChart = ({ data, timeframe }) => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });

    useEffect(() => {
        const now = Date.now();
        const oneDay = 24 * 60 * 60 * 1000;

        // Group data by date and actions
        const dataByDate = data.reduce((acc, item) => {
            const date = new Date(parseInt(item.date)).toLocaleDateString();
            if ((now - parseInt(item.date)) < (timeframe * oneDay) || timeframe === 'all') {
                acc[date] = acc[date] || {};
                const action = item.action;
                acc[date][action] = acc[date][action] ? acc[date][action] + 1 : 1;
            }
            return acc;
        }, {});

        // Extract unique actions and prepare chart data
        const labels = Object.keys(dataByDate);
        const actions = new Set(data.flatMap(item => item.action));
        const datasets = Array.from(actions).map(action => ({
            label: action,
            data: labels.map(label => dataByDate[label][action] || 0),
            borderColor: getColorForAction(action), // Assign specific colors for certain actions
        }));

        setChartData({
            labels,
            datasets
        });
    }, [timeframe, data]);

    return (
        <div style={{ height: '300px' }}>
            <Line data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
        </div>
    );
};

// Function to assign colors based on action type
const getColorForAction = (action) => {
    if (action.includes('failed') || action.includes('not')) {
        return '#FF6384'; // Tomato red for failed or not passed actions
    } else if (action.includes('done') || action.includes('passed')) {
        return '#36A2EB'; // Steel blue for done or passed actions
    }
    else if (action.includes('exported')) {
        return 'green'; // Steel blue for done or passed actions
    }
    else if (action.includes('Photo')) {
        return 'yellow'; // Steel blue for done or passed actions
    }
    return getRandomColor(); // Random colors for other actions
};

// Helper function to generate random colors
const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export default LineChart;
