import user from './User';

const socketInstances = {};
const listeners = {};

export const getSocketInstance = (url) => {
  if (!socketInstances[url] || socketInstances[url].readyState === WebSocket.CLOSED) {
    const socketInstance = new WebSocket(url, user.getAuthToken());

    socketInstance.onopen = () => {
      // WebSocket is connected
    };

    socketInstance.onerror = (error) => {
      console.error('WebSocket Error:', error);
      delete socketInstances[url];
    };

    socketInstance.onclose = () => {
      // WebSocket is closed
      delete socketInstances[url];
    };

    socketInstance.onmessage = (event) => {
      if (listeners[url]) {
        listeners[url].forEach(callback => callback(event));
      }
    };

    socketInstances[url] = socketInstance;
  }
  return socketInstances[url];
};

export const closeSocketInstance = (url) => {
  if (socketInstances[url] && socketInstances[url].readyState === WebSocket.OPEN) {
    socketInstances[url].close();
    delete socketInstances[url];
  }
};

export const addSocketListener = (url, callback) => {
  if (!listeners[url]) {
    listeners[url] = [];
  }
  listeners[url].push(callback);
};

export const removeSocketListener = (url, callback) => {
  if (listeners[url]) {
    listeners[url] = listeners[url].filter(listener => listener !== callback);
  }
};
