import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { SyncOutlined, DeleteOutlined, DownOutlined, RedoOutlined, CloseOutlined } from '@ant-design/icons';

const TopButtons = ({ handleTasksAction, selectedRowKeys, handleBulkAction }) => {
  const bulkMenuItems = [
    {
      key: 'restart',
      label: (
        <>
          <SyncOutlined /> Restart
        </>
      ),
    },
    {
      key: 'delete',
      label: (
        <>
          <DeleteOutlined /> Delete
        </>
      ),
    },
  ];

  return (
    <div className="topButtons">
      <Button icon={<RedoOutlined />} onClick={() => handleTasksAction('restartErrors')}>Restart errors</Button>
      <Button icon={<DeleteOutlined />} onClick={() => handleTasksAction('deleteErrors')}>Delete errors</Button>
      <Button icon={<DeleteOutlined />} onClick={() => handleTasksAction('deleteNew')}>Delete new</Button>
      {selectedRowKeys.length > 0 && (
        <Dropdown menu={{ items: bulkMenuItems, onClick: (e) => handleBulkAction(e.key) }}>
          <Button icon={<DownOutlined />}>
            Actions ({selectedRowKeys.length})
          </Button>
        </Dropdown>
      )}
    </div>
  );
};

export default TopButtons;
