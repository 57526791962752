import React from 'react';
import { Form, Radio, Button, Space, Input, Checkbox, Switch, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
const { Option } = Select;

const ZalivkiModeSettings = () => (
  <>
    <Form.Item label="Заливка по API" name="zalivkiModeAPI">
      <Radio.Group>
        <Space direction="vertical">
          <Radio value="enabled">Вкл</Radio>
          <Radio value="random">Random (50/50)</Radio>
          <Radio value="off">Выкл</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>

    <Form.Item label="Цель" name="objective">
      <Select>
        <Option value="OUTCOME_LEADS">Лиды</Option>
        <Option value="OUTCOME_AWARENESS">Узнаваемость</Option>
        <Option value="OUTCOME_TRAFFIC">Трафик</Option>
        <Option value="OUTCOME_ENGAGEMENT">Вовлеченность</Option>
        <Option value="OUTCOME_SALES">Продажи</Option>
      </Select>
    </Form.Item>

    <Form.Item label="Места" name="facebook_positions">
      <Select mode="multiple" placeholder="Выберите места">
        <Option value="feed">Лента</Option>
        <Option value="marketplace">Marketplace</Option>
        <Option value="video_feeds">Видеолента</Option>
        <Option value="story">Истории</Option>
        <Option value="facebook_reels">Reels</Option>
        <Option value="instream_video">Instream Video</Option>
        <Option value="search">Результаты поиска</Option>
      </Select>
    </Form.Item>

    <Form.Item label="Цель по результативности" name="optimization_goal">
      <Select>
          <Option value="OFFSITE_CONVERSIONS">Конверсии вне сайта</Option>
          <Option value="LINK_CLICKS">Переходы по ссылке</Option>
          <Option value="QUALITY_LEAD">Качественные лиды</Option>
          <Option value="LEAD_GENERATION">Генерация лидов</Option>
          <Option value="IMPRESSIONS">Просмотры</Option>
          <Option value="ENGAGED_USERS">Активные пользователи</Option>
          <Option value="AD_RECALL_LIFT">Улучшение запоминаемости рекламы</Option>
          <Option value="LANDING_PAGE_VIEWS">Просмотры целевой страницы</Option>
          <Option value="REACH">Охват</Option>
          <Option value="VALUE">Значение</Option>
          <Option value="QUALITY_CALL">Качественные звонки</Option>
          <Option value="THRUPLAY">Проигрывание до конца</Option>
          <Option value="NONE">Нет цели</Option>
      </Select>
    </Form.Item>


    <Form.Item label="Событие конверсии" name="pixel_event">
      <Select>
        <Option value="LEAD">Лид</Option>
        <Option value="PURCHASE">Покупка</Option>
        <Option value="CONTENT_VIEW">Просмотр контента</Option>
        <Option value="ADD_TO_CART">Добавление в корзину</Option>
      </Select>
    </Form.Item>



    <Form.Item
        label="BM lookalike Аудитории"
        name="createLookALike"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>

    <Form.Item
        label="BM Exclude Аудитории"
        name="createExclude"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>

    <Form.Item
        label="BM Retarget Аудитории"
        name="createRetarget"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>



    <Form.Item label="Брать акки" name="zalivkiMode">
      <Select>
        <Option value="all">Нужен перезалив + Ферма</Option>
        <Option value="onlyFerma">Только с Фермы</Option>
        <Option value="perezaliv">Только с Нужен перезалив</Option>
        <Option value="withCloacks">С клоаками</Option>
        <Option value="noCloacks">Без клоак</Option>
        <Option value="noPixel">Без пикселя</Option>
        <Option value="withPixel">С пикселем</Option>
      </Select>
    </Form.Item>

    <Form.Item label="Брать домены" name="zalivkiModeDomens">
      <Radio.Group>
        <Space direction="vertical">
          <Radio value="new">Только новые</Radio>
          <Radio value="all">Все</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>

    <Form.Item label="Брать клоаки" name="zalivkiModeCloacks">
      <Radio.Group>
        <Space direction="vertical">
          <Radio value="new">Только новые</Radio>
          <Radio value="all">Все</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>

    <Form.Item label="Статус заливки" name="zalivkiModeStatus">
      <Radio.Group>
        <Space direction="vertical">
          <Radio value="0">Новый</Radio>
          <Radio value="-10">Черновик</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>




    <Form.Item
        label="Публикация"
        name="zalivkiModePublish"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>





    <Form.Item
        label="Указывать язык"
        name="zalivkiModeUseLang"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>


    <Form.Item label="Ставить интересы" name="useInterests">
      <Radio.Group>
        <Space direction="vertical">
          <Radio value="enabled">Вкл</Radio>
          <Radio value="noMoreThen10">Не более 10</Radio>
          <Radio value="off">Выкл</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>

    <Form.Item
        label="Атрибуция 1 день"
        name="useAtribution"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>



    <Form.Item label="Отключить фильтры контента" name="useRelaxedFilter">
      <Radio.Group>
        <Space direction="vertical">
          <Radio value="enabled">Отключить фильтры</Radio>
          <Radio value="enabledAll">Включить фильтры</Radio>
          <Radio value="off">Выкл</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>



    <Space >
      <Form.Item
        label="Время запуска"
        name="adsStartTime"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.adsStartTime !== currentValues.adsStartTime}>
        {({ getFieldValue }) => {
          return getFieldValue('adsStartTime') === 'enabled' ? (
            <Form.Item name="adsStartTimeValue" label="Время:">
              <Input type="number" min="0" max="23" placeholder="4" />
            </Form.Item>
          ) : null;
        }}
      </Form.Item>
      </Space>




    <Form.Item
      label="Максимальная цена результата"
      name="maximalPrice"
      valuePropName="checked"
      getValueProps={(value) => ({ checked: value === 'enabled' })}
      getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
    >
      <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
    </Form.Item>

    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.maximalPrice !== currentValues.maximalPrice}>
      {({ getFieldValue }) => {
        return getFieldValue('maximalPrice') === 'enabled' ? (
          <Form.Item
            label="Ускоренный показ:"
            name="fastViews"
            valuePropName="checked"
            getValueProps={(value) => ({ checked: value === 'enabled' })}
            getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
          >
            <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
          </Form.Item>
        ) : null;
      }}
    </Form.Item>





      <Form.Item
        label="Не показывать во время стримов"
        name="liveDisabled"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>



    <Space >
      <Form.Item
        label="Делать дубли"
        name="zalivkiModeDuplicate"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.zalivkiModeDuplicate !== currentValues.zalivkiModeDuplicate}>
        {({ getFieldValue }) => {
          return getFieldValue('zalivkiModeDuplicate') === 'enabled' ? (
            <Form.Item name="zalivkiModeDuplicateCount" label="Кол:">
              <Input type="number" min="0" max="6" placeholder="2" />
            </Form.Item>
          ) : null;
        }}
      </Form.Item>
      </Space>




      <Form.Item
        label="Дубли A/B"
        name="useABTest"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>






    <Form.Item label="Привязка карт" name="zalivkiCardpos">
      <Radio.Group>
        <Space direction="vertical">
          <Radio value="0">В начале</Radio>
          <Radio value="1">В конце</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>


    <Form.Item
        label="Предоплата при привязке карты"
        name="zalivkiCardPrepay"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>

    </>
);

export default ZalivkiModeSettings;
