import React from 'react';
import { Tabs, Spin } from 'antd';
import TaskTable from './TaskTable';

const TaskTabs = ({ taskTabs, setSelectedRowKeys, setCurrentTab, selectedRowKeys, loading }) => {
  return (
    <Tabs
      defaultActiveKey="all"
      items={taskTabs.map(tab => ({
        key: tab.key,
        label: `${tab.label} (${tab.count})`,
        children: (
          <TaskTable
            tasks={tab.tasks}
            tasksData={tab.tasksData}
            activeBrowsers={tab.activeBrowsers}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            handleTaskAction={tab.handleTaskAction}
            loading={loading}
          />
        )
      }))}
      onChange={(key) => {
        setCurrentTab(key);
        setSelectedRowKeys([]);
      }}
    />
  );
};

export default TaskTabs;
