import React from 'react';
import { Form, Input, Radio, Button, Select, Switch, Space, Divider } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
const { Option } = Select;

const ZalivkiModeAutoSettings = () => {
  return (
    <>
      <Form.Item label="Режим работы" name="zalivkiModeAuto">
        <Select>
          <Option value="enabled">Все</Option>
          <Option value="withoutCloacks">Без клоак</Option>
          <Option value="withCloacks">С клоаками</Option>
          <Option value="withPixels">Только пиксели</Option>
          <Option value="off">Выкл</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Перезалить при отклонение" name="zalivkiModeAutoDecline">
        <Select>
          <Option value="enabled">Напролом</Option>
          <Option value="activeOnly">Только актив</Option>
          <Option value="waitOnly">Только в ожидание</Option>
          <Option value="off">Выкл</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Отправлять СТАРТ по АПИ" name="zalivkiModeAutoStartSend">
        <Select>
          <Option value="onlyActiv">Только Актив</Option>
          <Option value="onlyWait">Только в ожидание</Option>
          <Option value="enabled">Актив + Ожидание</Option>
          <Option value="off">Выкл</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Отправлять обжалование" name="zalivkiModeAutoObjalSend">
        <Select>
          <Option value="onlyActiv">Только Актив</Option>
          <Option value="onlyWait">Только в ожидание</Option>
          <Option value="enabled">Актив + Ожидание</Option>
          <Option value="off">Выкл</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Замена текстов вместо обжалование"
        name="zalivkiModeAutoObjalSendWithText"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>


      <Form.Item label="Тип уникализации видео при генерации коверов" name="automateVideoGeneration">
        <Radio.Group>
          <Radio value="enabled">10 кадров + сдвиг</Radio>
          <Radio value="onlyCover">Только 10 кадров</Radio>
          <Radio value="onlyScale">Только сдвиг</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Генерация коверов при замене текстов" name="changeTextextChangeCoverMidjourney">
        <Radio.Group>
          <Radio value="enabled">Midjourney</Radio>
          <Radio value="file">Из папки</Radio>
          <Radio value="fromWhite">Из Вайта</Radio>
          <Radio value="off">Выкл</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.changeTextextChangeCoverMidjourney !== currentValues.changeTextextChangeCoverMidjourney
        }
      >
        {({ getFieldValue }) => {
          return getFieldValue('changeTextextChangeCoverMidjourney') === 'file' ? (
            <Space style={{height: 'auto', alignItems: 'center',  marginBottom: 25 }}>
              <Divider type="vertical" style={{ height: 60 }} /> {/* Adjust height as needed */}
              <Form.Item name="changeTextextChangeCoverMidjourneyFile" style={{margin: "auto"}} label="Path">
                <Input name="changeTextextChangeCoverMidjourneyFile" placeholder="C:\Covers" />
              </Form.Item>
            </Space>
          ) : null;
        }}
      </Form.Item>




      <Form.Item label="Генерация коверов при заливке" name="changeTextextChangeCoverMidjourneyOnZaliv">
        <Radio.Group>
          <Radio value="enabled">Midjourney</Radio>
          <Radio value="file">Из папки</Radio>
          <Radio value="fromWhite">Из Вайта</Radio>
          <Radio value="off">Выкл</Radio>
        </Radio.Group>
      </Form.Item>


      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.changeTextextChangeCoverMidjourneyOnZaliv !== currentValues.changeTextextChangeCoverMidjourneyOnZaliv
        }
      >
        {({ getFieldValue }) => {
          return getFieldValue('changeTextextChangeCoverMidjourneyOnZaliv') === 'file' ? (
            <Space style={{height: 'auto', alignItems: 'center',  marginBottom: 25 }}>
              <Divider type="vertical" style={{ height: 60 }} /> {/* Adjust height as needed */}
              <Form.Item name="changeTextextChangeCoverMidjourneyFileOnZaliv" style={{margin: "auto"}} label="Path">
                <Input name="changeTextextChangeCoverMidjourneyFileOnZaliv" placeholder="C:\Covers" />
              </Form.Item>
            </Space>
          ) : null;
        }}
      </Form.Item>




      <Form.Item
        label="Конструктор крео"
        name="creoConstructor"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>


      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.creoConstructor !== currentValues.creoConstructor
        }
      >
        {({ getFieldValue }) => {
          return getFieldValue('creoConstructor') === 'enabled' ? (
            <Space style={{height: 'auto', alignItems: 'center',  marginBottom: 25 }}>
              <Divider type="vertical" style={{ height: 60 }} /> {/* Adjust height as needed */}
              <Form.Item name="creoConstructorPath" style={{margin: "auto"}} label="Path">
                <Input name="creoConstructorPath" placeholder="C:\CreoConstructor" />
              </Form.Item>
              <Form.Item name="creoConstructorTemplate" style={{margin: "auto"}} label="Template">
                <Input name="creoConstructorTemplate" placeholder="видео-анимация-бодяга-видео" />
              </Form.Item>
            </Space>
          ) : null;
        }}
      </Form.Item>




      <Form.Item
        label="Менять связку на ту для которой пиксель"
        name="zalivkiModeAutoChangeAdflowPixel"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>

      <Form.Item
        label="Менять связку если удалена"
        name="zalivkiModeAutoChangeAdflowDeleted"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>


      <Form.Item
        label="Менять связку если плохой рой"
        name="zalivkiModeAutoChangeAdflowBadStats"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>


      <Form.Item
        label="Менять связку если в стопе"
        name="zalivkiModeAutoChangeAdflowStop"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>


      <Form.Item
        label="Менять связку если нет креатива"
        name="zalivkiModeAutoChangeAdflowNoCreo"
        valuePropName="checked"
        getValueProps={(value) => ({ checked: value === 'enabled' })}
        getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
      >
        <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
      </Form.Item>


      </>
  );
};

export default ZalivkiModeAutoSettings;
