import React from 'react';
import { Form, Radio, Button, Space, Input } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const TextSettings = () => (
  <>
    <Form.Item label="Спрятать текст" name="hideText">
      <Radio.Group>
        <Space direction="vertical">
          <Radio value="stopWords">Стоп слова</Radio>
          <Radio value="allWords">Все слова</Radio>
          <Radio value="disabled">Не прятать слова</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
    <Form.Item label="Брать текста" name="textSettings">
      <Radio.Group>
        <Space direction="vertical">
          <Radio value="all">Все</Radio>
          <Radio value="my">Только мои</Radio>
          <Radio value="gpt">Только GPT</Radio>
          <Radio value="custom">
            Указанный текст
            <Form.Item name="textSettingscustomText" noStyle>
              <Input placeholder="Например: ." />
            </Form.Item>
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
    </>
);

export default TextSettings;
