import React from 'react';
import { Table, Spin, Button, App } from 'antd';
import { SyncOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons';
import DOMPurify from 'dompurify';
import AntyComponent from '../../globals/anty';
import { copyToClipboard } from '../../../helpers/Accounts';

const TaskTable = ({ tasks, tasksData, activeBrowsers, selectedRowKeys, setSelectedRowKeys, handleTaskAction, loading }) => {
  const { message } = App.useApp();
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text) => text,
    },
    {
      title: 'Browser',
      dataIndex: 'basId',
      key: 'basId',
      render: (text, record) => <AntyComponent account={record} activeBrowsers={activeBrowsers} />,
    },
    {
      title: 'Account',
      dataIndex: 'basId',
      key: 'basId',
      sorter: (a, b) => a.basId - b.basId,
      render: (text) => (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text, message)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'RK',
      dataIndex: 'accId',
      key: 'accId',
      sorter: (a, b) => a.accId.localeCompare(b.accId),
      render: (text) => (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text, message)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Task',
      dataIndex: 'func',
      key: 'func',
      render: (text) => (
        <>
          {tasksData[text]?.name ?? text}
        </>
      ),
      sorter: (a, b) => a.func.localeCompare(b.func)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => (
        <span
          style={{ color: status !== 'New' ? 'red' : '' }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(status) }}>
        </span>
      )
    },
    {
      title: 'Added',
      dataIndex: 'added',
      key: 'added',
      render: (timestamp) => new Date(timestamp * 1000).toLocaleString(),
      sorter: (a, b) => a.added - b.added,
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
      render: (timestamp) => new Date(timestamp * 1000).toLocaleString(),
      sorter: (a, b) => a.updated - b.updated,
    },
    {
      title: 'Tries',
      dataIndex: 'restart',
      key: 'restart',
      sorter: (a, b) => a.restart - b.restart
    },
    {
      title: 'Owner',
      dataIndex: 'login',
      key: 'login' // Added Owner column
    },
    {
      title: 'Restart',
      key: 'restartTask',
      render: (text, task) => <Button icon={<SyncOutlined />} onClick={() => handleTaskAction(task)}>Restart</Button>,
    },
    {
      title: 'Delete',
      key: 'deleteTask',
      render: (text, task) => <Button icon={<CloseOutlined />} onClick={() => handleTaskAction(task, true)}>Delete</Button>,
    },
  ];

  return (
    <Spin spinning={loading}>
      <Table
        dataSource={tasks}
        columns={columns}
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys,
          onSelectAll: (selected) => {
            if (selected) {
              const allTaskIds = tasks.map(task => task.id);
              setSelectedRowKeys(allTaskIds);
            } else {
              setSelectedRowKeys([]);
            }
          },
        }}
      />
    </Spin>
  );
};

export default TaskTable;
