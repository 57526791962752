import React, { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import './chartConfig'; // Ensure Chart.js components are registered

const PieChart = ({ data, timeframe }) => {
    const now = Date.now();
    const oneDay = 24 * 60 * 60 * 1000;

    // useMemo to calculate filtered data based on timeframe
    const filteredData = useMemo(() => {
        return data.filter(item => {
            const itemDate = parseInt(item.date);
            switch (timeframe) {
                case '1':
                    return (now - itemDate) < oneDay;
                case '7':
                    return (now - itemDate) < (7 * oneDay);
                case '30':
                    return (now - itemDate) < (30 * oneDay);
                case 'all':
                default:
                    return true; // No filtering for 'all'
            }
        });
    }, [data, timeframe, now, oneDay]);

    const chartData = useMemo(() => {
        const actionCounts = filteredData.reduce((acc, log) => {
            acc[log.action] = (acc[log.action] || 0) + 1;
            return acc;
        }, {});

        return {
            labels: Object.keys(actionCounts),
            datasets: [{
                data: Object.values(actionCounts),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
            }]
        };
    }, [filteredData]);

    return (
        <div style={{ height: '300px' }}>
            <Pie data={chartData} />
        </div>
    );
};

export default PieChart;
