import React, { useState, useEffect } from 'react';
import { Tabs, Input, Button, Spin, App, Modal } from 'antd';
import { PlusOutlined, ReloadOutlined, StopOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import user from '../../../helpers/User';
import CloakingForm from './CloakingForm';
import CloakingTable from './CloakingTable';

const { confirm } = Modal;

const Cloaking = () => {
  const { message } = App.useApp();
  const [activeData, setActiveData] = useState([]);
  const [disabledData, setDisabledData] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingBundle, setEditingBundle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [activeTab, setActiveTab] = useState('1'); // Track the active tab

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/cloaking/get`, { method: 'GET' });
      const data = response.data?.data ?? false;
      if (!data) {
        message.error(response?.data.message ?? 'Unknown error while fetching cloaking settings');
        return false;
      }
      const parsedData = data.map(item => ({
        ...item,
        country: typeof item.country === 'string' ? JSON.parse(item.country) : item.country,
        offer_name: item.offer.name,
        partner: item.offer.partner,
        payout: item.offer.payout,
        description: item.offer.description,
        notes: item.offer.notes,
        callcenter_details: item.offer.callcenter_details,
      }));
      setActiveData(parsedData.filter(item => item.status === 'active'));
      setDisabledData(parsedData.filter(item => item.status === 'disabled'));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const openEditDrawer = (record) => {
    setEditingBundle(record);
    setDrawerVisible(true);
  };

  const handleAddOrUpdate = async (formData) => {
    try {
      const apiEndpoint = editingBundle ? `${process.env.REACT_APP_API_URL}/cloaking/update` : `${process.env.REACT_APP_API_URL}/cloaking/add`;
      const response = await user.makeRequest(apiEndpoint, { method: 'POST', data: formData });
      const result = response.data;
      if (result?.result) {
        message.success("Done.");
      } else {
        message.error(result?.message ?? 'Unknown Error');
      }
      setDrawerVisible(false);
      setEditingBundle(null);
      fetchData();
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while saving data.');
    }
  };

  const handleDelete = async (record) => {
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/cloaking/delete`, { method: 'DELETE', data: { id: record.id } });
      const result = response.data;
      if (result?.result) {
        message.success("Cloaking setting deleted successfully.");
        fetchData();
      } else {
        message.error(result?.message ?? 'Unknown Error');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while deleting the cloaking setting.');
    }
  };

  const handleDisable = async (record) => {
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/cloaking/disable`, { method: 'POST', data: { id: record.id } });
      const result = response.data;
      if (result?.result) {
        message.success("Cloaking setting disabled successfully.");
        fetchData();
      } else {
        message.error(result?.message ?? 'Unknown Error');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while disabling the cloaking setting.');
    }
  };

  const handleEnable = async (record) => {
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/cloaking/enable`, { method: 'POST', data: { id: record.id } });
      const result = response.data;
      if (result?.result) {
        message.success("Cloaking setting enabled successfully.");
        fetchData();
      } else {
        message.error(result?.message ?? 'Unknown Error');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while enabling the cloaking setting.');
    }
  };

  const handleBulkDelete = async () => {
    confirm({
      title: 'Are you sure you want to delete selected cloaking settings?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await Promise.all(selectedRowKeys.map(id => user.makeRequest(`${process.env.REACT_APP_API_URL}/cloaking/delete`, { method: 'DELETE', data: { id } })));
          message.success("Selected cloaking settings deleted successfully.");
          setSelectedRowKeys([]);
          fetchData();
        } catch (error) {
          console.error('Error:', error);
          message.error('An error occurred while deleting selected cloaking settings.');
        }
      },
    });
  };

  const handleBulkDisable = async () => {
    try {
      await Promise.all(selectedRowKeys.map(id => user.makeRequest(`${process.env.REACT_APP_API_URL}/cloaking/disable`, { method: 'POST', data: { id } })));
      message.success("Selected cloaking settings disabled successfully.");
      setSelectedRowKeys([]);
      fetchData();
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while disabling selected cloaking settings.');
    }
  };

  const handleBulkEnable = async () => {
    try {
      await Promise.all(selectedRowKeys.map(id => user.makeRequest(`${process.env.REACT_APP_API_URL}/cloaking/enable`, { method: 'POST', data: { id } })));
      message.success("Selected cloaking settings enabled successfully.");
      setSelectedRowKeys([]);
      fetchData();
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while enabling selected cloaking settings.');
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const tabItems = [
    {
      key: '1',
      label: 'Active',
      children: (
        <>
          <Spin spinning={loading}>
            <CloakingTable data={activeData} loading={loading} onEdit={openEditDrawer} onDelete={handleDelete} onDisable={handleDisable} onEnable={handleEnable} rowSelection={rowSelection} />
          </Spin>
        </>
      ),
    },
    {
      key: '2',
      label: 'Disabled',
      children: (
        <>
          <Spin spinning={loading}>
            <CloakingTable data={disabledData} loading={loading} onEdit={openEditDrawer} onDelete={handleDelete} onDisable={handleDisable} onEnable={handleEnable} rowSelection={rowSelection} />
          </Spin>
        </>
      ),
    },
  ];

  const handleDrawerClose = () => {
    setDrawerVisible(false);
    setEditingBundle(null);
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="topButtons">
        <Button icon={<PlusOutlined />} onClick={() => setDrawerVisible(true)}>Add New Cloaking Setting</Button>
        <Button icon={<ReloadOutlined />} onClick={fetchData}>Refresh</Button>
        {selectedRowKeys.length > 0 && (
          <>
            {activeTab === '1' ? (
              <Button icon={<StopOutlined />} onClick={handleBulkDisable}>Disable Selected</Button>
            ) : (
              <Button icon={<CheckOutlined />} onClick={handleBulkEnable}>Enable Selected</Button>
            )}
            <Button icon={<DeleteOutlined />} onClick={handleBulkDelete}>Delete Selected</Button>
          </>
        )}
      </div>
      <Tabs
        defaultActiveKey="1"
        items={tabItems}
        onChange={(key) => {
          setActiveTab(key);
          setSelectedRowKeys([]); // Clear selection when tab changes
        }}
      />
      <CloakingForm
        open={drawerVisible}
        onCancel={handleDrawerClose}
        onSubmit={handleAddOrUpdate}
        editingBundle={editingBundle}
      />
    </div>
  );
};

export default Cloaking;
