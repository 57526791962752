import React from 'react';
import { Form, Radio, InputNumber, Button, Switch } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const ArtAntySettings = () => {
  return (
    <>
      <Form.Item
      label="Удалить браузеры при выходе"
      name="deleBrowsersOnlyOnExit"
      valuePropName="checked"
      getValueProps={(value) => ({ checked: value === 'enabled' })}
      getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
    >
      <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
    </Form.Item>


      <Form.Item
      label="Удалить папку профиля при выходе"
      name="deleBrowsersOnExit"
      valuePropName="checked"
      getValueProps={(value) => ({ checked: value === 'enabled' })}
      getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
    >
      <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
    </Form.Item>



    <Form.Item
      label="Выполнять задачи в фоне"
      name="artAntyBGTasks"
      valuePropName="checked"
      getValueProps={(value) => ({ checked: value === 'enabled' })}
      getValueFromEvent={(e) => (e ? 'enabled' : 'off')}
    >
      <Switch checkedChildren="Вкл" unCheckedChildren="Выкл" />
    </Form.Item>

      <Form.Item label="Максимальное количество одновременных задач" name="artAntyBGTasksCount">
        <InputNumber min={1} max={10} placeholder="2" />
      </Form.Item>
    </>
  );
};

export default ArtAntySettings;
